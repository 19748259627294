import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts'



const RADIAN = Math.PI / 180
const COLORS = ['#0ea5e9', '#7dd3fc', '#FF8042']

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {



	const radius = innerRadius + (outerRadius - innerRadius) * 0.5
	const x = cx + radius * Math.cos(-midAngle * RADIAN)
	const y = cy + radius * Math.sin(-midAngle * RADIAN)

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	)
}

export default function BuyerProfilePieChart() {
	const [dashboardData,setDashboardData] = useState('')
	// console.log('data',dashboardData)
	const getDashboardData = async()=>{
		const request = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`,{
			headers:{
				'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
				'Accept':'application/json'
			}
		})
		const response = await request.data
		if(response?.code ===200){
			if(response?.data?.complete>0){
				const newData = [{name:'Complete',value:response?.data?.complete},{name:'Incomplete',value:response?.data?.incomplete}]
				setDashboardData(newData)
			}else{
				const newData = [{name:'Incomplete',value:response?.data?.incomplete}]
				setDashboardData(newData)
			}
			// console.log('dashbord',newData)
		}
	}
	
	useEffect(()=>{
		getDashboardData()
	},[])

	return (
		<div className="w-[20rem] h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col">
			<strong className="text-gray-900 font-medium">Employee Profile</strong>
			<div className="mt-3 w-full flex-1 text-xs">
				<ResponsiveContainer width="100%" height="100%">
					<PieChart width={400} height={300}>
						<Pie
							data={dashboardData}
							cx="50%"
							cy="45%"
							labelLine={false}
							label={renderCustomizedLabel}
							outerRadius={110}
							fill="#8884d8"
							dataKey="value"
						>
							{dashboardData&&dashboardData.map((_, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Legend />
					</PieChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}