import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiFillPlusCircle, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { toast, ToastContainer } from 'react-toastify'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import TagsInput from '../../components/profile/TagsInput'

const Skills = ({nextButton,profileData,getUserData,handlePrevious,setShowToast,setToastMessage}) => {
  const [categoryOption,setCategoryOption] = useState('')
  const [skillsOption,setSkillsOption] = useState('')
  const [category,setCategory] = useState('')
  const [oldCategory,setOldCategory] = useState('')
  const [categoryId,setCategoryId] = useState('')
  const [oldData,setOldData] = useState([])

  
  // console.log("profile in skills",profileData)

  const [skills,setSkills] = useState(
        [ {
         skill:"",
         version:'',
         lastUsed:"",
         expOfYear:"",
        }]
       )
       
       const [award,setAward] = useState([])
       const [oldAward,setOldAward]= useState([])
       
    // console.log("award",award)

    useEffect(() => {
      if(profileData){
        const newData = profileData&&profileData?.skills?.map((x)=>(
            {
            skill:x?.skill===""||null||undefined?"":x?.skill,
            version:x?.version===""||null||undefined?"":x?.version,
            lastUsed:x?.last_used===""||null||undefined?"":x?.last_used,
            expOfYear:x?.exp_no_of_year===""||null||undefined?"":x?.exp_no_of_year,
          }
        ))
        const oldData = profileData&&profileData?.skills?.map((x)=>(
            {
              skill:x?.skill===""||null||undefined?"":x?.skill,
              version:x?.version===""||null||undefined?"":x?.version,
              lastUsed:x?.last_used===""||null||undefined?"":x?.last_used,
              expOfYear:x?.exp_no_of_year===""||null||undefined?"":x?.exp_no_of_year,
          }
        ))

        const newAwards = profileData&&profileData?.awards.map((x)=>(
            x?.award
        ))

        const oldAwards = profileData&&profileData?.awards.map((x)=>(
          x?.award
        ))

        setAward(newAwards)
        setOldAward(oldAwards)
        // console.log("new data",newData)
        setSkills(newData.length>0?newData:        [ {
          skill:"",
          version:'',
          lastUsed:"",
          expOfYear:"",
         }])
        setOldData(oldData.length>0?oldData:[ {
          skill:"",
          version:'',
          lastUsed:"",
          expOfYear:"",
         }])
        setCategory(profileData?.category === "" || null || undefined?"":profileData&& profileData?.category)
        setOldCategory(profileData?.category === "" || null || undefined?"":profileData&& profileData?.category)
      }
     }, [profileData])
     
     const loginUserData = JSON.parse(localStorage.getItem('Login'))



     
     const handleSubmit = async(e)=>{
       e.preventDefault()
       // dispatch(updateProfileAsync(profile))
       try {     
           const formData = new FormData()
           for(let i =0; i<skills.length;i++){
             formData.append(`skills[${i}][skill]`,skills[i]?.skill!== null || undefined||""?skills[i]?.skill:"")
             formData.append(`skills[${i}][version]`,skills[i]?.version!== null || undefined||""?skills[i]?.version:"")
             formData.append(`skills[${i}][last_used]`,skills[i]?.lastUsed!== null || undefined||""?skills[i]?.lastUsed:"")
             formData.append(`skills[${i}][exp_no_of_year]`,skills[i]?.expOfYear!== null || undefined||""?skills[i]?.expOfYear:"")
             for(let i=0;i<award.length;i++){
               formData.append(`awards[${i}][award]`,award[i]!== null || undefined||""?award[i]:"")
             }
             formData.append(`category`,category?.label!== null || undefined||""?category?.label:'')
           }
           if(loginUserData?.id === profileData?.id){
             const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/skillupdate`,formData,{
               headers:{
                   'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                   'Accept':'application/json'
               }
             })
             const response = await request?.data
             if(response?.code === 200){
               // console.log('response',response)
               // response
               setShowToast(true)
               setToastMessage(`${response?.message}`)
               getUserData()
             }
           }else{
            const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/skillupdate/${profileData?.id}`,formData,{
              headers:{
                  'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                  'Accept':'application/json'
              }
            })
            const response = await request?.data
            if(response?.code === 200){
              // console.log('response',response)
              // response
              setShowToast(true)
              setToastMessage(`${response?.message}`)
              getUserData()
            }
          }
       } catch (error) {
          //  alert(error)
           // console.log('error',error)
           console.log('error',error)
           if(error?.response?.data?.error){
               const errors = Object.values(error?.response?.data?.error)
               console.log('Errors',errors)
               errors.map((x)=>(
                   // console.log("Error",x)
                   toast.error(`${x}`)
               ))
           }
           if(error?.response?.data?.message){
               if(error?.response?.data?.error){
                   const errors = Object.values(error?.response?.data?.error)
                   console.log('Errors',errors)
                   errors.map((x)=>(
                       // console.log("Error",x)
                       toast.error(`${x}`)
                   ))
               }
               if(error?.response?.data?.message){
                   toast.error(`${error?.response?.data?.message}`)
               }
           }
       }
     }
     
     const handleMore = (e)=>{
       e.preventDefault()
       let newField= {
        skill:"",
        version:'',
        lastUsed:"",
        expOfYear:"",
       }
       setSkills([...skills, newField])
     }
     
     const handleChange = (index,e)=>{
       let data = [...skills];
       data[index][e.target.name] = e.target.value;
       setSkills(data)
     }

     const handleSelectChange = (index,e,{nothing})=>{
      let data = [...skills]
      data[index][nothing]=e.label
      setSkills(data)
    }
     



  
  const getCategories = async()=>{
    const request = await axios.get(`https://stagingcipl.ciplcrm.org.in/api/admin/jobcategory`)
    const response = await request?.data
    console.log("response of category list",response)
    if(response?.code === 200){
      setCategoryOption(response&&response?.data?.map(x=>({
        'label':x?.name,
        'value':x?.id
      })))
      const filterID = response&&response?.data?.filter((id)=>id?.name?.includes(category))
      console.log('filter id',filterID)
      if(filterID.length>0){
        setCategoryId(filterID[0]?.id)
      }else{
        setCategoryId('')
      }
    }
}

useEffect(()=>{
  getCategories()
},[])

console.log("category in state",category)
console.log("categoryid in state",categoryId)


const getSkills = async()=>{
  console.log('skills call')
  const request = await axios.get(`https://stagingcipl.ciplcrm.org.in/api/admin/skills/search/${categoryId}`)
  const response = await request?.data
  console.log("response of skills list",response)
  if(response?.code === 200){
      setSkillsOption(response?.data?.map(x=>({
        'label':x?.name,
        'value':x?.id
      })))
  }else{
    setSkillsOption([{label:"Please Select Category",value:""}])
  }
}
useEffect(()=>{
  if(categoryId){
      getSkills()
  }
},[categoryId])

const checkPrevious = (e)=>{
  e.preventDefault()
  // console.log("check next work")
  if(JSON.stringify(oldData) === JSON.stringify(skills) && JSON.stringify(oldCategory)===JSON.stringify(category)){
    handlePrevious()
    
  }else{
    Swal.fire({
      title: 'Are you sure to Move on Previous Page?',
      text: "Unsaved!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Move to Previous!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        handlePrevious()
}})
  }
}

const checkNext = (e)=>{
  e.preventDefault()
  // console.log("check next work")
  if(JSON.stringify(oldData) === JSON.stringify(skills) && JSON.stringify(oldCategory)===JSON.stringify(category)){
    nextButton()
  }else{
    Swal.fire({
      title: 'Are you sure to Move on Next Page?',
      text: "Unsaved!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Move to Next!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        nextButton()
}})
  }
}
     
const removeFields = (index) => {
  // console.log("remove field is working!")
  let data = [...skills];
  data.splice(index, 1)
  setSkills(data)
}

const selectedTags = award => {
  console.log("Award",award);
};
console.log("Award",Object.values(award));

       return (
         <div>
          <ToastContainer
          position='top-center'
          />
           <form  onSubmit={handleSubmit}  className="grid grid-cols-3 my-3">
            <div className='col-span-1 px-2 w-full justify-center'>
              <label className={` font-bold h-6 mt-3 text-gray-600  after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize`}>Category</label>
            <Select
              required={true}
              placeholder={profileData&&profileData.category !== null||""||" "||undefined?category:"Please Select Category"}
              // value={category&&category}
              className=''
              options={profileData&&profileData.category !== null||""||" "||undefined?categoryOption:[{'label':'Please Select Category','value':""}]}
              onChange={(e)=>{
                setCategory(e)
                setCategoryId(e.value)
              }}
              />
            </div>
             {
                skills?.length===0?
                <div className='flex col-span-3 justify-center w-full '>
                    <span className='text-lg text-center'>Please Add Skill Details..!!</span>
                </div>
                :
             skills && skills?.map((x,index)=>(
               <div key={index} className={`col-span-3 grid grid-cols-12 ${index !== 0 ? "border-t" : ""}  border-neutral-900`}>
                 <div className={`w-full px-2 col-span-3`} >
                     <div className="font-bold h-6 mt-3 text-gray-600  after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Skill</div>
                     <div className={`bg-white  my-2 flex border border-gray-200 rounded`}>

                     <Select
                      options={
                        categoryId?skillsOption:[{'label':'Please Select Category','value':""}]
                    }
                      onChange={(e)=>{
                          handleSelectChange(index,e,{nothing:"skill"})
                      }}
                      required
                      placeholder={`${x?.skill !==""?x?.skill:"Search Skills"}`}
                      className='block w-full  rounded-sm'
                      />
                     </div>
                 </div>
                 <div className={`w-full px-2 col-span-3`} >
                     <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize">Version</div>
                     <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                       <input name='version' value={x?.version}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                     </div>
                 </div>
                 <div className={`w-full px-2 col-span-3`} >
                     <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Last Used</div>
                     <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                       <input required name='lastUsed' min="2000-01-02" type={"date"} value={x.lastUsed} onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                     </div>
                 </div>
                 <div className={`w-full px-2 col-span-2`} >
                     <div className="font-bold h-6 mt-3 text-gray-600  after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Year of Experience</div>
                     <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                       <input name='expOfYear' required type={'number'} value={x.expOfYear}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                     </div>
                 </div>
                 {
                  index === 0 ?
                    null :
                    <div className='col-span-1 w-full mb-2 flex justify-center items-center'>
                      <div onClick={() => removeFields(index)} className='cursor-pointer px-2 pt-10 flex justify-center items-center '><span className='text-red-500 px-6 py-2 flex items-center justify-center'><RiDeleteBin6Fill size={26} /></span></div>
                    </div>
                }
                {
                  index ===0?

             <div className='py-2 flex justify-center px-2 col-span-1'>
                 <button onClick={handleMore} className='px-4 pt-6 ml-1 flex items-center  delay-75 duration-75'><span className='px-6 py-2 flex items-center justify-center'><AiFillPlusCircle size={26}/></span></button>
             </div>:null
                }
               </div>
                 ))
             }
             <div className='p-2 col-span-1'>
              <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize">Awards</div>
              <TagsInput selectedTags={selectedTags}  tags={award} setTags={setAward} />
             </div>
             <div className='col-span-3 px-2 py-2  flex justify-end'>
                <button onClick={(e)=>checkPrevious(e)} className='px-5 py-2 mr-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineLeft/></button>
                 <button type='submit' className='px-5 py-2 mr-1 bg-teal-700 text-neutral-50 hover:bg-teal-800 delay-75 duration-75'>Save</button>
                 <button onClick={(e)=>checkNext(e)} className='px-5 py-2 ml-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineRight/></button>
             </div>
         </form>
         </div>
       )
}

export default Skills