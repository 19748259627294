import React, { useEffect, useState } from 'react'
import { FaPencilRuler } from 'react-icons/fa'
import { MdWork } from 'react-icons/md'
import { FiBarChart2, FiClipboard, FiUser } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import Personal from './Personal'
import axios from 'axios'
import Education from './Education'
import { toast, ToastContainer } from 'react-toastify'
import Employment from './Employment'
import Skills from './Skills'
import Project from './Project'


const AdminProfileView = () => {
    const { state } = useLocation()
    // console.log('state',state)
    const [showToast, setShowToast] = useState(false)
    const [currentSection, setCurrentSection] = useState('Personal')
    const [profileData, setProfileData] = useState('')
    const [toastMessage, setToastMessage] = useState('')

    useEffect(() => {
        if (showToast === true) {
            toast.success(`${toastMessage}`)
            setTimeout(() => {
                handleNext();
            }, 7000);
        }
    }, [showToast])


    const localData = JSON.parse(localStorage.getItem('LoginToken'))


    const findUserData = async () => {
        const request = await axios.get(`${process.env.REACT_APP_API_URL}/finduser/${state}`, {
            headers: {
                'Authorization': `Bearer ${localData}`
            }
        })
        const response = await request.data
        console.log("response", response)
        if (response?.code === 200) {
            setShowToast(false)
            setProfileData(response?.data)
        }
    }


    useEffect(() => {
        if (state) {
            findUserData()
        }
    }, [state])

    const section = [
        {
            id: 1,
            svg: <FiUser size={25} />,
            label: "Personal"
        },
        // {
        //     id:2,
        //     svg:<FiUserPlus size={25}/>,
        //     label:"Profile"
        // },
        {
            id: 2,
            svg: <FiClipboard size={25} />,
            label: "Education"
        },
        {
            id: 3,
            svg: <FiBarChart2 size={25} />,
            label: "Skills"
        },
        {
            id: 4,
            svg: <FaPencilRuler size={25} />,
            label: "Employment"
        },
        // {
        //     id:6,
        //     svg:<HiDocumentAdd size={25}/>,
        //     label:"Certification"
        // },
        {
            id: 5,
            svg: <MdWork size={25} />,
            label: "Project"
        },
    ]

    const handleNext = () => {
        const data = (section?.filter(x => x?.label === currentSection))
        if (data[0]?.id) {
            const newId = data[0]?.id < 5 ? data[0].id + 1 : 1
            const newData = section?.filter(x => x.id === newId)
            setCurrentSection(newData[0]?.label)
        }
        setShowToast(false)
    }

    const handlePrevious = () => {
        const data = (section?.filter(x => x?.label === currentSection))
        if (data[0]?.id) {
            const newId = data[0]?.id > 1 ? data[0].id - 1 : 5
            const newData = section?.filter(x => x.id === newId)
            setCurrentSection(newData[0]?.label)
        }
        setShowToast(false)
    }


    return (
        <div >
            {
                showToast ?
                    <ToastContainer autoClose={4000} />
                    : null
            }
            <div>
                <div className='2xl:mx-6 xl:mx-5 md:mx-4 sm:mx-3 mx-2'>
                    <h2 className='text-2xl font-bold capitalize'>Profile Edit</h2>
                    {/* <span className='text-sm font-normal text-neutral-900'>Edit Profile of a Candidate</span> */}
                </div>
                <div className='w-full'>
                    <div className="mx-4 p-2">
                        <div className="flex items-center h-full pl-2">
                            {
                                section && section?.map((section, index) => (
                                    <>
                                        <div onClick={() => setCurrentSection(section?.label)} key={index}>
                                            <div className={`flex items-center  ${section?.label === currentSection ? "bg-teal-500 text-neutral-50" : "text-neutral-700"} rounded-full  relative cursor-pointer`}>
                                                <div className="rounded-full flex justify-center items-center transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-teal-600">
                                                    {section?.svg}
                                                </div>
                                                <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">{section?.label}</div>
                                            </div>
                                        </div>
                                        {
                                            section?.id !== 5 ?
                                                <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-teal-600"></div>
                                                : null
                                        }
                                    </>
                                ))
                            }
                        </div>

                    </div>
                </div>
            </div>
            <div className="mt-2 p-2">
                <div className='mx-2'>
                    {
                        currentSection === "Personal" ?
                            <Personal profileData={profileData} handlePrevious={handlePrevious} setShowToast={setShowToast} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={findUserData} nextButton={handleNext} />
                            :
                            currentSection === "Education" ?
                                <Education setToastMessage={setToastMessage} handlePrevious={handlePrevious} setShowToast={setShowToast} toastMessage={toastMessage} getUserData={findUserData} profileData={profileData} nextButton={handleNext} />
                                :
                                currentSection === "Employment" ?
                                    <Employment setShowToast={setShowToast} handlePrevious={handlePrevious} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={findUserData} profileData={profileData} nextButton={handleNext} />
                                    :
                                    currentSection === "Skills" ?
                                        <Skills setShowToast={setShowToast} handlePrevious={handlePrevious} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={findUserData} profileData={profileData} nextButton={handleNext} />
                                        :
                                        // currentSection==="Certification"?
                                        // <Certification setShowToast={setShowToast} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={getUserData} profileData={profileData} nextButton={handleNext}/>
                                        // :
                                        currentSection === "Project" ?
                                            <Project setShowToast={setShowToast} handlePrevious={handlePrevious} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={findUserData} profileData={profileData} nextButton={handleNext} />
                                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default AdminProfileView