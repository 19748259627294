import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Select from 'react-select'

const AdminPersonal = ({profileData,getUserData,setShowToast,setToastMessage}) => {


    const [profile,setProfile] = useState({
        firstName:"",
        middleName:'',
        lastName:"",
        email:"",
        employeeId:"",
        phone:"",
        profile:"",
        jobProfile:"",
        file:null
    })


    const localLoginData = JSON.parse(localStorage.getItem('Login'))
    
    useEffect(() => {
      if(profileData){
        setProfile({
            firstName:profileData?.first_name,
            middleName:profileData?.middle_name !== null || "null"? profileData?.middle_name:"",
            lastName:profileData?.last_name !== null || "null"? profileData?.last_name:"",
            email:profileData?.email,
            employeeId:profileData?.emp_id,
            phone:profileData?.phone_no,
            jobProfile:profileData?.job_profile,
            profile:profileData?.profile
        })
      }
    }, [profileData])
    
    const profileInput = [
        {
            label:"First Name",
            type:"text",
            id:"firstName",
            name:"firstName",
            key:profile?.firstName,
            required:true,
            value:profile?.firstName,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Middle Name",
            type:"text",
            id:"middleName",
            name:"middleName",
            key:profile?.middleName,
            required:false,
            value:profile?.firstName,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Last Name",
            type:"text",
            id:"lastName",
            name:"lastName",
            key:profile?.lastName,
            required:true,
            value:profile?.lastName,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Email",
            type:"email",
            id:"email",
            name:"email",
            key:profile?.email,
            required:true,
            value:profile?.email,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Employee ID",
            type:"text",
            id:"employeeId",
            name:"employeeId",
            key:profile?.employeeId,
            required:false,
            value:profile?.employeeId,
            columnLength:"col-span-1",
            disable:true,
            maxlength:100,
        },
    ]

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }
      

    const handleSubmit = async(e)=>{
        e.preventDefault()
        try {     
            const formData = new FormData()
            formData.append('email',profile?.email)
            formData.append('emp_id',profile?.employeeId)
            formData.append('first_name',profile?.firstName)
            formData.append('middle_name',profile?.middleName)
            formData.append('last_name',profile?.lastName)
            formData.append('phone_no',profile?.phone)
            formData.append('profile',profile?.file)
            formData.append('job_profile',profile?.jobProfile)
            if(localLoginData?.id === profileData?.id){
                const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/personalprofile`,formData,{
                  headers:{
                      'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                      'Accept':'application/json'
                  }
                })
                const response = await request?.data
                if(response?.code === 200){
                  toast.success(response?.message)
                  getUserData()
                }
            }else{
                const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/personalprofile/${profileData?.id}`,formData,{
                  headers:{
                      'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                      'Accept':'application/json'
                  }
                })
                const response = await request?.data
                if(response?.code === 200){
                  // console.log('response',response)
                  // response
                  setToastMessage(response?.message)
                  getUserData()
                }
            }

        } catch (error) {
            // alert(error)
            // console.log('error',error)
            if(error?.response?.data?.error){
                const errors = Object.values(error?.response?.data?.error)
                console.log('Errors',errors)
                errors.map((x)=>(
                    // console.log("Error",x)
                    toast.error(`${x}`)
                ))
            }
            if(error?.response?.data?.message){
                if(error?.response?.data?.error){
                    const errors = Object.values(error?.response?.data?.error)
                    console.log('Errors',errors)
                    errors.map((x)=>(
                        // console.log("Error",x)
                        toast.error(`${x}`)
                    ))
                }
                if(error?.response?.data?.message){
                    toast.error(`${error?.response?.data?.message}`)
                }
            }
        }
    }



  return (
    <>
        <ToastContainer
          position='top-center'
        />
    <form onSubmit={handleSubmit} className="grid grid-cols-3">
        {
        profileInput && profileInput?.map((x,index)=>(
                <div className={`w-full px-2 ${x?.columnLength}`} key={index}>
                    <div className={`font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize ${x?.required?"after:content-['*'] after:ml-0.5 after:text-red-500":""}`}>{x?.label}</div>
                    <div className={`${x.disable?"bg-gray-200":"bg-white"}  my-2 p-1 flex border border-gray-200 rounded`}>
                        <input disabled={x?.disable} maxLength={x?.maxlength} value={x?.key} placeholder={x?.label} id={x?.id} name={x?.name} type={x?.type} required={x?.required} onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800 ${x.disable?"bg-gray-200 cursor-pointer":""}`}/> </div>
                </div>
            ))
        }
                <div className={`w-full px-2 col-span-1`}>
                    <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 uppercase">Phone</div>
                    <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                        <input maxLength={10} value={profile?.phone} onInput={maxLengthCheck} placeholder={"Enter Phone Number"} id="phone" name="phone" type={'number'} required={true} minLength={10} onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> </div>
                </div>

        <div className='col-span-3 flex w-full'>
            <div className='p-2 w-2/3'>
                <label className='block'>Job Profile</label>
                <textarea name="jobProfile" value={profile?.jobProfile} onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} className='h-24 rounded-sm w-full'></textarea>
            </div>
            <div className='p-2 w-1/3 '>
                <div className="shrink-0 flex py-3 justify-center">
                    <img className="h-16 w-16 object-cover rounded-full" src={profile?.profile} />
                </div>
                <label className="block border border-gray-300 p-1">
                    <div className='flex justify-center'>
                    <span className="sr-only">Choose profile photo</span>
                    <input type="file" className="block px-2 text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-100 file:text-violet-700
                    hover:file:bg-violet-100
                    "
                    name='file'
                    accept='image/x-png,image/gif,image/jpeg'
                    onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.files[0]})}
                    />
                    </div>
                </label>
            </div>
        </div>
        <div className='col-span-3 px-2 py-2  flex justify-end'>
            <button type='submit' className='px-5 py-2 mr-1 bg-teal-700 text-neutral-50 hover:bg-teal-800 delay-75 duration-75'>Save</button>
        </div>
    </form>
    </>
  )
}

export default AdminPersonal