export const howToUse = [
    {
        label:'Serial Number',
        key:"${slno}"
    },
    {
        label:'Date',
        key:"${date}"
    },
    {
        label:'First Name',
        key:"${first_name}"
    },
    {
        label:'Middle Name',
        key:"${middle_name}"
    },
    {
        label:'Last Name',
        key:"${last_name}"
    },
    {
        label:'C/O',
        key:"${origin}"
    },
    {
        label:'Course',
        key:"${course}"
    },
    {
        label:'DOB',
        key:"${dob}"
    },
   
    {
        label:'District',
        key:"${district}"
    },
    {
        label:'Remark',
        key:"${remarks}"
    },
    
    ]