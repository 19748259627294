import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BiCircle } from 'react-icons/bi'
// import { SlSpeedometer } from 'react-icons/sl'
import { AiTwotoneSetting, AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_SETTING_LINKS } from '../../lib/consts/Sidebar'
import logo from '../../assets/logowest.png'
import adminpdf from '../../assets/AUTOMATED_RESUME_BUILDE1.pdf'
import userpdf from '../../assets/userprofile.pdf'
import classNames from 'classnames'
import { HiDocumentText, HiOutlineLogout, HiQuestionMarkCircle } from 'react-icons/hi'


const linkClass =
	'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-900 hover:no-underline active:bg-neutral-900 rounded-sm text-base'


const SideBar = ({ showSideBar, setShowSideBar }) => {
	const [showSetting, setShowSetting] = useState(false)
	// const [showExam, setShowExam] = useState(false)
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const data = JSON.parse(localStorage.getItem('Login'))
	const role = data && data?.role
	// const  data  = JSON.parse(localStorage.getItem('Login'))
	// console.log("links",DASHBOARD_SIDEBAR_LINKS.filter(x=>x?.role?.includes(role)))
	return (
		<div className={`flex flex-col transition-all overflow-y-scroll no-scrollbar delay-150 duration-150 z-50 bg-primary ${showSideBar ? " translate-x-0 w-60 p-3 transition-all duration-100 delay-100  " : " -translate-x-60 transition-all p-0 duration-100 w-0 delay-100"} inset-y-0 left-0 absolute lg:relative text-white`}>

			<div className='flex items-center justify-center gap-2 border-b border-white px-1 lg:py-3 py-2'>
				<Link to="/admin" className='cursor-pointer'>
					<img src={logo} alt='CIPL Logo' className='h-24 w-48 ' />
				</Link>
			</div>
			<div className={`flex flex-1 flex-col ${showSideBar ? "py-1" : "hidden"} py-1`}>
				{DASHBOARD_SIDEBAR_LINKS?.filter(x=>x?.role?.includes(role))?.map((item, index) =>

					<button key={index} onClick={() => navigate(item?.path)} className={`flex items-center cursor-pointer my-1 px-1 py-2 rounded ${pathname === item.path ? "bg-[#374768]" : "bg-transparent hover:bg-white hover:text-primary "} `} >
						<span className='text-xl px-2'>
							{item.icon}
						</span>
						<span className='text-md px-2 font-light' >
							{item.label}
						</span>
					</button>
				)}
			</div>
				<div className='w-full flex flex-col  gap-0.5 pt-2 border-t border-white'>
								{/* <a className={`flex items-center cursor-pointer my-1 ml-1 py-2 rounded ${pathname === "/admin/usermanual" ? "bg-[#374768]" : "bg-transparent hover:bg-white hover:text-primary"} `} rel="noopener noreferrer" href={role==="admin"?adminpdf:userpdf} target="_blank" style={{ textDecoration: 'none' }}>
									
										<span className="text-xl px-2" >
											<HiDocumentText />
										</span>
										<span className='text-md font-light'>
										User Manual
										</span>
								</a> */}
					{
						role === "admin" ?
							<>
								<div
									onClick={() => navigate('/admin/howtouse')}
									className={classNames(linkClass, `cursor-pointer  ${pathname === "/admin/howtouse" ? "bg-neutral-900 text-white" : "bg-transparent hover:bg-white hover:text-gray-900"}`)}>
									<span className="" >
										<HiQuestionMarkCircle size={20} />
									</span>
									How to Use?
								</div>
							</>
							: null
					}
					<div
						onClick={(e) => {
							e.preventDefault()
							localStorage.removeItem('Login')
							localStorage.removeItem('LoginToken')
							navigate('/')
						}}
						className={classNames(linkClass, 'cursor-pointer text-red-500 hover:bg-white hover:text-red-500')}>
						<span className="text-xl" >
							<HiOutlineLogout />
						</span>
						Logout
					</div>
				</div>
			{/* <div className=''>Sidebar</div> */}
		</div>
	)
}


export default SideBar