import React from 'react'
import {howToUse} from '../../lib/consts/HowToUse'

const HowToUse = () => {
  return (
    <div className='w-full h-full'>
    <div className='w-full bg-white p-2'>
        <h2 className='text-2xl font-light text-gray-500 py-1'>How to Use?</h2>
        <span className='text-md font-normal text-gray-700 py-1'>This helps you to understand the key fields that is used in template..!</span>
        <div className='p-2'>
            <div className='grid grid-cols-2 bg-gray-50 rounded'>
                {
                    howToUse && howToUse?.map((s)=>(
                       <div className=' col-span-2 grid grid-cols-2' key={s?.key}>
                <div className='bg-gray-100 text-center rounded-sm col-span-1 p-2 m-1'>
                <label className=''>{s?.label}</label>
                </div>
                <div onClick={() =>  navigator&&navigator.clipboard.writeText(`${s?.key}`)} 
                    className='relative cursor-pointer col-span-1 text-center p-2 bg-gray-100 rounded-sm m-1'>
                    <div class="group no-underline cursor-pointer relative inline-block w-28 text-center">
                        {s?.key}
                        <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full -left-1/2 ml-14 px-3 pointer-events-none">
                            Click to Copy
                        </div>
                    </div>
                </div>
                       </div> 
                    ))
                }
            </div>
        </div>
    </div>
</div>
  )
}

export default HowToUse