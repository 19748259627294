import React from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; 

const Table = ({data, columns,downloadheading}) => {
  
  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: downloadheading&&downloadheading.map((c) => c?.header),
  };
  
  const csvExporter = new ExportToCsv(csvOptions);
  
  // const handleExportRows = (rows) => {
  //   csvExporter.generateCsv(rows.map((row) => row?.original));
  // };

  // const handleExportData = () => {
  //   if(data.length>0){
  //     csvExporter.generateCsv(data&&data);
  //   }
  // };

  return (
    <MaterialReactTable
      columns={columns}
      data={data&&data}
      enableRowSelection
      options={{
        pageSize: 5,
        pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100 ],
        toolbar: true,
        paging: true,
        tabLayout:"fixed"
    }}
      positionToolbarAlertBanner="bottom"
      muiTableHeadCellProps={{
        //easier way to create media queries, no useMediaQuery hook needed.
        sx: {
          fontSize: {
            xs: '10px',
            sm: '11px',
            md: '12px',
            lg: '13px',
            xl: '14px',
          },
        },
      }}
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      enableFullScreenToggle={false}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          {/* <Button
            style={{
                backgroundColor:"#171717"
            }}
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data
          </Button>
          <Button
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Selected Rows
          </Button> */}
        </Box>
      )}
    />
  );
};

export default Table;
