import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Select from 'react-select'

const Index= () => {

  const navigate = useNavigate()

  const location = useLocation()
  const regForm = useRef()
  
  const [courseList, setCourseList] = useState([]);

 

  async function fetchCourseList() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/courselist`, {
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
          'Accept': 'application/json'
        }
      });
      const courseData = response.data;
      setCourseList(courseData);
    } catch (error) {
      console.error('Error fetching course list:', error);
    }
  }
  
  console.log('update' ,courseList)

  useEffect(() => {
    fetchCourseList();
  }, []);

  const initialState = {
      firstName:'',
      middleName:'',
      lastName:'',
      origin:'',
      dob:'',
      course:'',
      district:'',
      remarks:'',
      slno:'',
  }

  const [employee,setEmployee] = useState(initialState)
  console.log('employee' , employee)

  const data = JSON.parse(localStorage.getItem('Login'))
  useEffect(()=>{
    if(data?.role !=="admin"){
      navigate('/admin')
    }
  },[location])

  useEffect(()=>{
		document.title="Certificate Generator"
	},[])
	

const handleSubmit = async(e)=>{
  // console.log("Employee",employee)
  // console.log("Notify",notify)
  // console.log("Joining",isjoining)
  e.preventDefault()

    try {
      const formData = new FormData()
      formData.append('first_name',employee?.firstName)
      formData.append('middle_name',employee?.middleName)
      formData.append('last_name',employee?.lastName)
      formData.append('slno' , employee.slno)
      formData.append('origin',employee?.origin)
      formData.append('dob',employee?.dob)
      formData.append('course',employee?.course)
      formData.append('district',employee?.district)
      formData.append('remarks',employee?.remarks)

      const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/register`,formData,{
        headers:{
            'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
            'Accept':'application/json'
        }
      })
      const response = await request?.data
      if(response?.code === 200){
        toast.success(`${response?.message}`)
        setEmployee(initialState)
        regForm.current.reset()
      }
    } catch (error) {
      console.log('error',error)
      if(error?.response?.data?.error){
          const errors = Object.values(error?.response?.data?.error)
          console.log('Errors',errors)
          errors.map((x)=>(
              // console.log("Error",x)
              toast.error(`${x}`)
          ))
      }
      if(error?.response?.data?.message){
          if(error?.response?.data?.error){
              const errors = Object.values(error?.response?.data?.error)
              console.log('Errors',errors)
              errors.map((x)=>(
                  // console.log("Error",x)
                  toast.error(`${x}`)
              ))
          }
          if(error?.response?.data?.message){
              toast.error(`${error?.response?.data?.message}`)
          }
      }
  }
}


const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
   object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }


// console.log("Employee",employee)
  
  return (
    <div className='w-[98%]'>
      <ToastContainer 
      position='top-center'
      autoClose={3000}/>
      <div>
          <div className='mx-2'>
              <h2 className='text-2xl font-bold capitalize '>Add Student</h2>
              {/* <span className='text-sm font-normal text-neutral-900'>Create a New Employee Profile</span> */}
          </div>
      </div>
      <div className='w-full mt-4'>
        <div className="grid grid-cols-3">
          <form onSubmit={handleSubmit} ref={regForm} className='col-span-3 grid grid-cols-3'>
          <div className='w-full px-2 col-span-1' >
              <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Serial number</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input type='text' name='slno'  placeholder='Serial number' required onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>
          <div className={`w-full px-2 col-span-1`} >
              <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize ">First Name</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input name='firstName' placeholder='First Name' required  onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none  outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>
          <div className={`w-full px-2 col-span-1`} >
              <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize ">Middle Name</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input name='middleName' placeholder='Middle Name' onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>
          <div className='w-full px-2 col-span-1' >
              <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Last Name</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input name='lastName'  placeholder='Last Name' required onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>
          
          <div className='w-full px-2 col-span-1' >
              <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Company of Origin (C/O)</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input  name='origin'  placeholder='Compony of Origin' required onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>
          <div className='w-full px-2 col-span-1' >
              <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Date of Birth</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input type='date' name='dob'  placeholder='Date of Birth' required onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>
          
          <div className='w-full px-2 col-span-1'>
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Course</div>
            {/* <div className={`bg-white my-2 p-1 flex border border-gray-200 rounded w-full`}> */}
              {courseList.data && (
                <Select
                  required
                  onChange={selectedOption => setEmployee({ ...employee, course: selectedOption.value })}
                  options={courseList.data.map(course => ({ value: course.id, label: course.course }))}
                  className='w-full my-2'
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: courseList.data.length > 5 ? '200px' : 'auto', // Set maxHeight conditionally
                      overflowY: 'scroll', // Enable vertical scrolling if needed
                    }),
                  }}
                />
              )}
            {/* </div> */}
          </div>

          <div className='w-full px-2 col-span-1' >
              <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">District</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input  name='district' required placeholder='District' onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>
          <div className='w-full px-2 col-span-1' >
              <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Remarks</div>
              <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input  name='remarks' placeholder='Remark' required onChange={(e)=>setEmployee({...employee,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
              </div>
          </div>

          
          <div className='col-span-1 p-2 items-end  flex mt-8'>
              <button type='submit' className='px-5 py-2 font-semibold bg-neutral-800 border border-neutral-900 hover:shadow-lg  text-neutral-50 hover:bg-neutral-800 rounded-sm delay-75 duration-75'>Submit</button>
          </div>
            </form>
        </div>
      </div>
    </div>
  )
}

export default Index

