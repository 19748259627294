import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { AiFillPlusCircle, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { toast, ToastContainer } from 'react-toastify'
import Swal from 'sweetalert2'

const Employment = ({nextButton,profileData,handlePrevious,getUserData,setShowToast,setToastMessage}) => {
    const [jobProfile,setJobProfile] = useState('')
    const formRef = useRef()
    const [oldData,setOldData] = useState([])

    const [employment,setEmployement] = useState(
        [ {
         employer:"",
         position:'',
         fromDate:"",
         toDate:"",
        }]
       )
       
       
       useEffect(() => {
         if(profileData){
           const newData = profileData&&profileData?.employementdetails?.map((x)=>(
               {
               employer:x?.employer,
               position:x?.position,
               fromDate:x?.from_date,
               toDate:x?.to_date,
             }
           ))
           const oldData = profileData&&profileData?.employementdetails?.map((x)=>(
               {
               employer:x?.employer,
               position:x?.position,
               fromDate:x?.from_date,
               toDate:x?.to_date,
             }
           ))
           console.log("new data",newData)
           setEmployement(newData.length>0?newData:        [ {
            employer:"",
            position:'',
            fromDate:"",
            toDate:"",
           }])
           setOldData(oldData.length>0?oldData:[ {
            employer:"",
            position:'',
            fromDate:"",
            toDate:"",
           }])
           setJobProfile(profileData?.job_profile)
         }
     }, [profileData])
     
     const loginUserData = JSON.parse(localStorage.getItem('Login'))

     
const checkPrevious = (e)=>{
  e.preventDefault()
  // console.log("check next work")
  if(JSON.stringify(oldData) === JSON.stringify(employment)){
    handlePrevious()
  }else{
    Swal.fire({
      title: 'Are you sure to Move on Previous Page?',
      text: "Unsaved!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Move to previous!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        handlePrevious()
}})
  }
}

const checkNext = (e)=>{
  e.preventDefault()
  // console.log("check next work")
  if(JSON.stringify(oldData) === JSON.stringify(employment)){
    nextButton()
  }else{
    Swal.fire({
      title: 'Are you sure to Move on Next Page?',
      text: "Unsaved!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Move to Next!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        nextButton()
}})
  }
}
     
     const handleSubmit = async(e)=>{
       e.preventDefault()
       // dispatch(updateProfileAsync(profile))
       try {     
           const formData = new FormData()
           for(let i =0; i<employment.length;i++){
             formData.append(`employments[${i}][employer]`,employment[i]?.employer)
             formData.append(`employments[${i}][position]`,employment[i]?.position)
             formData.append(`employments[${i}][from_date]`,employment[i]?.fromDate)
             formData.append(`employments[${i}][to_date]`,employment[i]?.toDate)
           }
           formData.append('job_profile',jobProfile)
           if(loginUserData?.id === profileData?.id){
             const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/employmentdetailsupdate`,formData,{
               headers:{
                   'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                   'Accept':'application/json'
               }
             })
             const response = await request?.data
             if(response?.code === 200){
               // console.log('response',response)
               // response
               setShowToast(true)
               setToastMessage(`${response?.message}`)
               getUserData()
             }
           }else{
            const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/employmentdetailsupdate/${profileData?.id}`,formData,{
              headers:{
                  'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                  'Accept':'application/json'
              }
            })
            const response = await request?.data
            if(response?.code === 200){
              // console.log('response',response)
              // response
              setShowToast(true)
              setToastMessage(`${response?.message}`)
              getUserData()
            }
           }
       } catch (error) {
          //  alert(error)
           // console.log('error',error)
           console.log('error',error)
           if(error?.response?.data?.error){
               const errors = Object.values(error?.response?.data?.error)
               console.log('Errors',errors)
               errors.map((x)=>(
                   // console.log("Error",x)
                   toast.error(`${x}`)
               ))
           }
           if(error?.response?.data?.message){
               if(error?.response?.data?.error){
                   const errors = Object.values(error?.response?.data?.error)
                   console.log('Errors',errors)
                   errors.map((x)=>(
                       // console.log("Error",x)
                       toast.error(`${x}`)
                   ))
               }
               if(error?.response?.data?.message){
                   toast.error(`${error?.response?.data?.message}`)
               }
           }
       }
     }
     
     const handleMore = (e)=>{
       e.preventDefault()
       let newField= {
        employer:"",
        position:'',
        fromDate:"",
        toDate:"",
       }
       setEmployement([...employment, newField])
     }
     
     const handleChange = (index,e)=>{
       let data = [...employment];
       data[index][e.target.name] = e.target.value;
       setEmployement(data)
     }

     const removeFields = (index) => {
      console.log("remove field is working!")
      let data = [...employment];
      data.splice(index, 1)
      setEmployement(data)
    }
     
     
     
       return (
         <div>
          <ToastContainer
          position='top-center'
          />
           <form onSubmit={handleSubmit} ref={formRef} className="grid grid-cols-2 my-3">
             {
                employment.length===0?
                <div className='flex justify-center w-full col-span-2'>
                    <span className='text-lg text-center'>Please Add Employment Details..!!</span>
                </div>
                :
             employment && employment?.map((x,index)=>(
               <div key={index} className={`col-span-2 grid grid-cols-12 ${index !== 0 ? "border-t" : ""}  border-neutral-900`}>
                 <div className={`w-full px-2 col-span-3`} >
                     <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Employer</div>
                     <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                       <input name='employer' required value={x.employer} onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                     </div>
                 </div>
                 <div className={`w-full px-2 col-span-3`} >
                     <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize">Position</div>
                     <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                       <input name='position' value={x.position}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                     </div>
                 </div>
                 <div className={`w-full px-2 col-span-2`} >
                     <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">From Date</div>
                     <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                       <input name='fromDate' min="2000-01-02" required type={"date"} value={x.fromDate} onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                     </div>
                 </div>
                 <div className={`w-full px-2 col-span-2`} >
                     <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">To Date</div>
                     <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                       <input name='toDate' min="2000-01-02" required type={'date'} value={x.toDate}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                     </div>
                 </div>
                 {
                  index === 0 ?
                    null :
                    <div className='col-span-2 w-full mb-4 flex items-end  justify-center '>
                      <div onClick={() => removeFields(index)} className='cursor-pointer flex justify-center items-center '><span className='text-red-500 px-4 py-2 flex items-center justify-center '><RiDeleteBin6Fill size={26} /></span></div>
                    </div>
                }
                {
                  index ===0?

             <div className='py-2 flex justify-center px-2 col-span-1'>
                 <button onClick={handleMore} className='px-4 pt-6 ml-1 flex items-center  delay-75 duration-75'><span className='px-6 py-2 flex items-center justify-center'><AiFillPlusCircle size={26}/></span></button>
             </div>:null
                }
               </div>
                 ))
             }
            <div className='col-span-3 flex w-full'>
              <div className='p-2 w-1/2'>
                  <label className={`font-bold h-6 mt-3 text-gray-600  after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize`}>Job Profile</label>
                  <textarea name="jobProfile" value={jobProfile} onChange={(e)=>setJobProfile(e.target.value)} className='h-24 rounded-sm w-full'></textarea>
              </div>
            </div>
             <div className='col-span-3 px-2 py-2  flex justify-end'>
                 <button type='submit' onClick={checkPrevious} className='px-5 py-2 mr-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineLeft/></button>
                 <button type='submit' className='px-5 py-2 mr-1 bg-teal-700 text-neutral-50 hover:bg-teal-800 delay-75 duration-75'>Save</button>
                 <button type='submit' onClick={checkNext} className='px-5 py-2 ml-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineRight/></button>
             </div>
         </form>
         </div>
       )
}

export default Employment