import axios from 'axios'
import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'

const UpdatePassword = () => {
    const [password,setPassword] = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const [oldPassword,setOldPassword] = useState('')

    // console.log("password",password)
    // console.log('confirm password',confirmPassword)

    const handleSubmit = async(e)=>{
        e.preventDefault()
        if(oldPassword===""){
            toast.error("Enter old password.!")
        }
        if(password===""){
            toast.error('Enter Password')
        }
        if(confirmPassword===""){
            toast.error('Enter Confirm Password')
        }if(password!==confirmPassword){
            toast.error('Please recheck your password')
        }if(password===confirmPassword){
            try {
                console.log("this is running api")
                const formData = new FormData()
                formData.append('password',password)
                formData.append('password_confirmation',confirmPassword)
                formData.append('oldpassword',oldPassword)
                const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/passwordresetupdate`,formData,{
                    headers:{
                        'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                    }
                })
                const response = await request?.data
                console.log("response",response)
                if(response?.code===200){
                    toast(`Password Updated Succesfully`)
                }
            } catch (error) {
                console.log("error",error)
                if(error?.response?.data?.error){
                    const errors = Object.values(error?.response?.data?.error)
                    console.log('Errors 1',errors)
                    errors.map((x)=>(
                        // console.log("Error",x)
                        toast.error(`${x}`)
                    ))
                  }
                  if(error?.response?.data?.message){
                      if(error?.response?.data?.error){
                          const errors = Object.values(error?.response?.data?.error)
                          console.log('Errors 2',errors)
                          errors.map((x)=>(
                              // console.log("Error",x)
                              toast.error(`${x}`)
                          ))
                      }
                      if(error?.response?.data?.message){
                          toast.error(`${error?.response?.data?.message}`)
                      }
                  }
            }
        }
    }
  return (
    <div>
        <ToastContainer
        position='top-center'
        />
        <div className='bg-white p-2 '>
            <div className='px-2'>
                <h2 className='text-2xl font-bold capitalize'>Update Password</h2>
                {/* <span className='text-sm font-normal text-neutral-900'>Update your new Password..!</span> */}
            </div>
            <form onSubmit={handleSubmit} className='grid grid-cols-3 p-2'>
                <div className='col-span-1 p-1'>
                    <label className='block m-1 font-semibold'>Old Password</label>
                    <div className='p-1 border border-gray-200 rounded-sm m-1'>
                    <input type='password' required placeholder='Old password' onChange={(e)=>setOldPassword(e.target.value)} className="rounded-sm w-full" />
                    </div>
                </div>
                <div className='col-span-1 p-1'>
                    <label className='block m-1 font-semibold'>New Password</label>
                    <div className='p-1 border border-gray-200 rounded-sm m-1'>
                    <input type='password' required placeholder='Password' onChange={(e)=>setPassword(e.target.value)} className="rounded-sm w-full" />
                    </div>
                </div>
                <div className='col-span-1 p-1'>
                    <label className='block m-1 font-semibold'>Confirm New Password</label>
                    <div className='p-1 border border-gray-200 rounded-sm m-1'>
                    <input type={'password'} required placeholder='Confirm Password' onChange={(e)=>setConfirmPassword(e.target.value)} className="rounded-sm w-full" />
                    </div>
                </div>
                <div className='col-span-1 px-2 w-full flex justify-start p-1'>
                    <button onClick={(e)=>handleSubmit(e)} className='px-6 py-2 rounded-sm text-white bg-neutral-900 hover:bg-neutral-800 font-semibold delay-75 duration-75 cursor-pointer'>
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default UpdatePassword