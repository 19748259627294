import React, { useState } from "react";
import './tags.css'

const TagsInput = ({tags,selectedTags,setTags }) => {
    
    const addTags = event => {
        if (event.target.value !== "") {
          setTags([...tags, event.target.value]);
          selectedTags([...tags, event.target.value]);
          event.target.value = "";
        }
      };
    const removeTags = indexToRemove => {
        setTags([...tags.filter((_, index) => index !== indexToRemove)]);
      };
    return (
        <div className="tags-input">
          <ul id="tags">
            {tags.map((tag, index) => (
              <li key={index} className="tag">
                <span className='tag-title'>{tag}</span>
                <span className='tag-close-icon'
                  onClick={() => removeTags(index)}
                >
                  x
                </span>
              </li>
            ))}
          </ul>
          <input
            type="text"
            onKeyUp={event => event.key === " " ? addTags(event) : null}
            placeholder="Press space to add tags"
          />
        </div>
      );
};
export default TagsInput;