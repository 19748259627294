import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MaterialReactTable from 'material-react-table';
import { FaDownload, FaFileImport } from 'react-icons/fa';
//import {AiFillEye} from 'react-icons/ai'
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
//import pdf from '../../components/AUTOMATED_RESUME_BUILDE1.pdf'
import { BsFillPencilFill } from 'react-icons/bs';

const Index = () => {
    const [showEditModal,setShowEditModal] = useState(false)
    const [editText,setEditText] = useState('')
    const [templateId,setTemplateId] = useState('')
    const [updateFile,setUpdateFile] = useState()

    useEffect(()=>{
		document.title="Certificate Generator"
	},[])
	

    const loginToken = JSON.parse(localStorage.getItem('LoginToken'))
    const handleDelete = async(cell)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                    const getTemplate = await axios.get(`${process.env.REACT_APP_API_URL}/template/delete/${cell?.row?.original?.id}`,{
                        headers:{
                            'Authorization':`Bearer ${loginToken}`,
                            'Accept':"application/json"
                        }
                    })
                    const response = await getTemplate?.data
                    setShowApiErrorModal(true)
                    toast.error(`${response?.message}`)
                    getTemplateList()     
                } catch (error) {
                    setShowApiErrorModal(true)
                    console.log('error',error)
                    if(error?.response?.data?.error){
                        const errors = Object.values(error?.response?.data?.error)
                        console.log('Errors',errors)
                        errors.map((x)=>(
                            // console.log("Error",x)
                            toast.error(`${x}`)
                        ))
                    }
                    if(error?.response?.data?.message){
                        if(error?.response?.data?.error){
                            const errors = Object.values(error?.response?.data?.error)
                            console.log('Errors',errors)
                            errors.map((x)=>(
                                // console.log("Error",x)
                                toast.error(`${x}`)
                            ))
                        }
                        if(error?.response?.data?.message){
                            toast.error(`${error?.response?.data?.message}`)
                        }
                    }
                }        
            }}
            )
        }
    const handleEdit = (cell)=>{
        setShowEditModal(true)
        setEditText(cell?.row?.original?.template_name)
        setTemplateId(cell?.row?.original?.id)
    }

    const handleView = (cell)=>{
        window.open(` https://cr.livelakeeducation.com/public/template_name/${cell?.row?.original?.file_name}`, '_blank', 'noreferrer')
        // window.open(` https://arbbacakend.ciplcrm.org.in/public/template_name/${cell?.row?.original?.file_name}`, '_blank', 'noreferrer')
    }
    
    const heading = [
        {
            accessorKey: 'id',
            header: 'ID',
            size: 40,
        },
        {
            accessorKey: 'template_name',
            header: 'Template Name',
            size: 200,
        },
        {
            header:"Action",
            Cell:({cell})=>
                <div className='flex'>
                <button onClick={()=>handleEdit(cell)} className='px-4 hover:shadow-lg border rounded-sm border-neutral-900 justify-center delay-75 duration-75 bg-neutral-900 mr-1 hover:bg-neutral-800 text-neutral-50 py-2 flex items-center'><span className='px-1'><BsFillPencilFill size={16}/></span>Update</button>
                <button onClick={()=>handleView(cell)} className='px-4 flex rounded-sm hover:shadow-lg border border-blue-900 items-center justify-center ml-1 delay-75 duration-75 bg-blue-900 hover:bg-blue-800 text-neutral-50 py-2'><span className='px-1'><FaDownload size={20}/></span>Download</button>
                {/* <button onClick={()=>handleDelete(cell)} className='px-4 flex rounded-sm hover:shadow-lg border border-red-900 items-center justify-center ml-1 delay-75 duration-75 bg-red-900 hover:bg-red-800 text-neutral-50 py-2'><span className='px-1'><AiTwotoneDelete size={20}/></span>Delete</button> */}
                </div>
        },
    ]

    const [showImportModal,setShowImportModal] = useState(false)
    const [showApiErrorModal,setShowApiErrorModal] = useState(false)
    const [file,setFile] = useState('')
    const [data,setData] = useState('')
    const [templateName,setTemplateName] = useState('')

    const getTemplateList = async()=>{
        const getTemplate = await axios.get(`${process.env.REACT_APP_API_URL}/template`,{
            headers:{
                'Authorization':`Bearer  ${loginToken}`,
                'Accept':"application/json"
            }
        })
        const response = await getTemplate?.data
        // console.log('response',response)
        if(response?.data){
            setData(response?.data)
        }
    }

    useEffect(()=>{
        getTemplateList()
    },[])

    
    const navigate = useNavigate()

    useEffect(()=>{
      const data = JSON.parse(localStorage.getItem('Login'))
      if(data?.role !=="admin"){
        navigate('/admin')
      }
    },[])


  return (
    <>
    {
    showApiErrorModal?
    <ToastContainer autoClose={8000}/>
    :null
    }
    {
        showEditModal?
        <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
        <div className="absolute bg-black opacity-90 inset-0 z-0" onClick={()=>setShowEditModal(false)}></div>
            <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
            
            <form>
                <div className='flex justify-end' onClick={()=>setShowEditModal(false)}>
                    <span  className='text-neutral-50 hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
                </div>
            
                <div  className="text-center p-3">
                    <div className='flex justify-center'>
                    <FaFileImport className='text-neutral-900' size={30}/>
                    </div>
                    <h2 className="text-xl font-bold py-4 ">Update Template</h2>
                    <div htmlFor="dropzone-file" className='border p-4 cursor-pointer border-neutral-900'>
                        <div className='text-left px-1 py-2 flext justify-center'>
                            <label className='block'>Template Name<span className='text-red-500'>*</span></label>
                            <div className='border border-neutral-800 rounded-sm my-1'>
                            <input required placeholder='Template Name' value={editText} onChange={(e)=>setEditText(e.target.value)} className="border w-full border-neutral-800 p-2" />
                            </div>
                        </div>
                        <div className='flex justify-center p-1 '>
                            <input required id="dropzone-file" accept="application/msword,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" className="border file:text-neutral-50 w-full file:bg-neutral-800 border-neutral-800" onChange={(e)=>setUpdateFile(e.target.files[0])} />  
                        </div>
                    </div>
                </div>
                <div className="p-3  mt-2 text-center space-x-4 md:block">
                    <button type='submit' onClick={async(e)=>{
                        e.preventDefault()
                            const formData= new FormData()
                            formData.append('template_name',editText)
                            formData.append('file_name',updateFile)
                            try {
                                const sendFile = await axios.post(`${process.env.REACT_APP_API_URL}/template/update/${templateId}`,formData,{
                                    headers:{
                                        'Authorization':`Bearer  ${loginToken}`,
                                        'Content-Type': 'multipart/form-data',
                                        'Accept': 'application/json'
                                    }
                                })
                                const responseData = await sendFile?.data
                                if(responseData){
                                    setShowApiErrorModal(true)
                                    toast.success(`${responseData?.message}`)
                                    setShowEditModal(false)
                                    getTemplateList()
                                }if(responseData?.code === 404){
                                    toast.error(`${responseData?.data}`)
                                }
                                
                            } catch (error) {
                                setShowEditModal(true)
                                console.log('error',error)
                                if(error?.response?.data?.error){
                                    const errors = Object.values(error?.response?.data?.error)
                                    console.log('Errors',errors)
                                    errors.map((x)=>(
                                        // console.log("Error",x)
                                        toast.error(`${x}`)
                                    ))
                                }
                                if(error?.response?.data?.message){
                                    if(error?.response?.data?.error){
                                        const errors = Object.values(error?.response?.data?.error)
                                        console.log('Errors',errors)
                                        errors.map((x)=>(
                                            // console.log("Error",x)
                                            toast.error(`${x}`)
                                        ))
                                    }
                                    if(error?.response?.data?.message){
                                        toast.error(`${error?.response?.data?.message}`)
                                    }
                                }
                            }
                        }} 
                        className="mb-2 md:mb-0 bg-neutral-900 border border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-neutral-800">
                        Update
                    </button>
                </div>
            </form>
        </div>
        </div>
        :null
    }

    {
        showImportModal?
    <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
   	<div className="absolute bg-black opacity-90 inset-0 z-0" onClick={()=>setShowImportModal(false)}></div>
    <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      
    <form>
        <div className='flex justify-end' onClick={()=>setShowImportModal(false)}>
            <span  className='text-neutral-50 hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
        </div>
      
        <div  className="text-center p-3">
            <div className='flex justify-center'>
            <FaFileImport className='text-neutral-900' size={30}/>
            </div>
            <h2 className="text-xl font-bold py-4 ">Import File</h2>
            <div htmlFor="dropzone-file" className='border-2 p-4 cursor-pointer border-dashed border-neutral-900'>
                <div className='text-left px-1 py-2 flext justify-center'>
                    <label className='block'>Template Name<span className='text-red-500'>*</span></label>
                    <input required placeholder='Template Name' onChange={(e)=>setTemplateName(e.target.value)} className="border w-full border-neutral-800 p-2" />
                </div>
                <div className='flex justify-center p-1 '>
                    <input required id="dropzone-file" accept="application/msword,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" className="border file:text-neutral-50 w-full file:bg-neutral-800 border-neutral-800" onChange={(e)=>setFile(e.target.files[0])} />  
                </div>
            <p className="text-sm text-neutral-800 ">Choose your DOC or DOCX file. </p>
            </div>
        </div>
        <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button type='submit' onClick={async(e)=>{
                e.preventDefault()
                if(file){
                    const formData= new FormData()
                    formData.append('file_name',file)
                    formData.append('template_name',templateName)
                    try {
                        const sendFile = await axios.post(`${process.env.REACT_APP_API_URL}/template/store`,formData,{
                            headers:{
                                'Authorization':`Bearer  ${loginToken}`,
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            }
                        })
                        const responseData = await sendFile?.data
                        if(responseData){
                            setShowApiErrorModal(true)
                            toast.success(`${responseData?.message}`)
                            setShowImportModal(false)
                            getTemplateList()
                        }if(responseData?.code === 404){
                            toast.error(`${responseData?.data}`)
                        }
                        
                    } catch (error) {
                        setShowApiErrorModal(true)
                        // console.log('error',error)
                        if(error?.response?.data?.error){
                            const errors = Object.values(error?.response?.data?.error)
                            console.log('Errors 1',errors)
                            errors.map((x)=>(
                                // console.log("Error",x)
                                toast.error(`${x}`)
                            ))
                        }
                        if(error?.response?.data?.message){
                            if(error?.response?.data?.error){
                                const errors = Object.values(error?.response?.data?.error)
                                console.log('Errors 2',errors)
                                errors.map((x)=>(
                                    // console.log("Error",x)
                                    toast.error(`${x}`)
                                ))
                            }
                            if(error?.response?.data?.message){
                                toast.error(`${error?.response?.data?.message}`)
                            }
                        }
                    }
                }
                // setShowImportModal(false)
                }} className="mb-2 md:mb-0 bg-neutral-900 border rounded-sm border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white hover:shadow-lg hover:bg-neutral-800">
                Submit
            </button>
            {/* <a href={require('../../components/user_sheet.xlsx')} download={"sample-sheet"+JSON.stringify(date)} >
                <button className="mb-2 md:mb-0 bg-neutral-900 border border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-neutral-800">Download Demo</button>
            </a> */}
        </div>
      </form>
    </div>
    </div>
:null
    }
    <div className='w-full'>
    <div className='mx-2'>
        <div className='flex  justify-between items-center'>
            <div>
                <h2 className='text-2xl font-bold capitalize'>Templates</h2>
                {/* <span className='text-sm font-normal text-neutral-900'>List of Template..</span> */}
            </div>
            <div>
            {/* <button onClick={()=>navigate('/admin/howtouse')} className='px-6 h-10 mr-2 border border-neutral-900 hover:shadow-sm rounded-sm bg-neutral-900 text-white hover:bg-neutral-700 font-normal delay-75 duration-75'>
                How to use?
            </button> */}
            <button onClick={()=>setShowImportModal(true)} className='px-6 h-10 border border-neutral-900 hover:shadow-sm rounded-sm bg-neutral-900 text-white hover:bg-neutral-700 delay-75 duration-75'>
                Import Template
            </button>
            </div>
        </div>
        <div className='w-full mt-4'>
            <MaterialReactTable
                enableFullScreenToggle={false} columns={heading} data={data} />
        </div>
    </div>
</div>
    </>
  )
}

export default Index