import React, { useEffect, useState } from 'react'
import { FaPencilRuler } from 'react-icons/fa'
import {MdWork} from 'react-icons/md'
import {FiBarChart2, FiClipboard, FiUser, FiUserPlus} from 'react-icons/fi'
import { HiDocumentAdd } from 'react-icons/hi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Personal from './Personal'
import axios from 'axios'
import Education from './Education'
import { toast, ToastContainer } from 'react-toastify'
import Employment from './Employment'
import Skills from './Skills'
import Project from './Project'


const ProfileEdit = () => {
    const {state} = useLocation()
    // console.log('state',state)
    const [showToast,setShowToast] = useState(false)
    const [currentSection,setCurrentSection] = useState('Personal')
    const [profileData,setProfileData] = useState('')
    const [toastMessage,setToastMessage] = useState('')
    const [categoryOption,setCategoryOption] = useState('')
    const [skillsOption,setSkillsOption] = useState('')

    console.log("profile",currentSection)

    useEffect(()=>{
        if(showToast===true){
            toast.success(`${toastMessage}`)
            setTimeout(() => {
                handleNext();
              }, 7000);
        }
    },[showToast])


    const localData = JSON.parse(localStorage.getItem('LoginToken'))


    const findUserData= async()=>{
        console.warn('Find users works')
        const request = await axios.get(`${process.env.REACT_APP_API_URL}/finduser/${state}`,{
            headers:{
                'Authorization':`Bearer ${localData}`
            }
        })
        const response = await request.data
        // console.log("response",response)
        if(response?.code ===200){
            setShowToast(false)
            setProfileData(response?.data)
        }
    }

    const getUserData = async()=>{
        const request = await axios.get(`${process.env.REACT_APP_API_URL}/userdetails`,{
            headers:{
                'Authorization':`Bearer ${localData}`
            }
        })
        const response = await request.data
        if(response?.code ===200){
            localStorage.removeItem('Login')
            setShowToast(false)
            setProfileData(response?.data[0])
            const newData = {
                email:response?.data[0]?.email,
                emp_id:response?.data[0]?.emp_id,
                first_name:response?.data[0]?.first_name,
                id:response?.data[0]?.id,
                job_profile:response?.data[0]?.job_profile,
                last_name:response?.data[0]?.last_name,
                middle_name:response?.data[0]?.middle_name,
                phone_no:response?.data[0]?.phone_no,
                profile:response?.data[0]?.profile,
                role:response?.data[0]?.role,
            }
            localStorage.setItem('Login',JSON.stringify(newData))
        }
    }


    
    

    const getCategories = async()=>{
        const request = await axios.get(`https://stagingcipl.ciplcrm.org.in/api/admin/jobcategory`)
        const response = await request.data
        // console.log("response of category list",response)
        if(response?.code === 200){
          setCategoryOption(response?.data)
        }
    }
  
    const categoryId = categoryOption&&categoryOption.filter((x)=>x.name.includes(profileData?.category))
    // const categoryId = profileData?.category?.filter(x=>categoryOption&&categoryOption?.filter(y=>y?.name?.includes(x))) 
    // console.log("Category",categoryId[0]?.id)
        
    const getSkills = async()=>{
        if(categoryId){
        const request = await axios.get(`https://stagingcipl.ciplcrm.org.in/api/admin/skills/search/${categoryId&&categoryId[0]?.id}`)
        const response = await request.data
        // console.log("response of skills list",response)
        if(response?.code === 200){
            setSkillsOption(response?.data)
        }
        }
    }

    useEffect(()=>{
    getCategories()
},[])

    useEffect(()=>{
        if(profileData?.category){
            getSkills()
        }
    },[profileData])


    
    // console.log("currention section",currentSection)
    // console.log("profile data",profileData)
    // console.log("Profile Data in Edit Profile",profileData)

    useEffect(()=>{
        // dispatch(fetchProfileAsync())
        if(state === null || undefined || ""){
            getUserData()
        }else{
            // console.log('find user data')
            findUserData()
        }
    },[state])

    const section=[
        {
            id:1,
            svg:<FiUser size={25}/>,
            label:"Personal"
        },
        // {
        //     id:2,
        //     svg:<FiUserPlus size={25}/>,
        //     label:"Profile"
        // },
        {
            id:2,
            svg:<FiClipboard size={25}/>,
            label:"Education"
        },
        {
            id:3,
            svg:<FaPencilRuler size={25}/>,
            label:"Employment"
        },
        {
            id:4,
            svg:<FiBarChart2 size={25}/>,
            label:"Skills"
        },
        // {
        //     id:6,
        //     svg:<HiDocumentAdd size={25}/>,
        //     label:"Certification"
        // },
        {
            id:5,
            svg:<MdWork size={25}/>,
            label:"Project"
        },
    ]

    const handleNext = ()=>{
        const data = (section?.filter(x=>x?.label ===currentSection))
        // console.log("Index of",section.indexOf())
        // console.log("data",data[0]?.id)
        if(data[0]?.id){
            const newId = data[0]?.id<6?data[0].id+1:1
            // console.log("new id",newId)
            const newData = section?.filter(x=>x.id===newId)
            // console.log("new data",newData)
            setCurrentSection(newData[0]?.label)
        }
        setShowToast(false)
    }


  return (
    <div className=''>
        {
            showToast?
            <ToastContainer autoClose={6000}/>
            :null
        }
        <div>
            <div className='mx-4'>
                <h2 className='text-2xl font-bold uppercase'>Profile Edit</h2>
                <span className='text-sm font-normal text-neutral-900'>Edit Profile of a Candidate</span>
            </div>
            <div className='w-full mt-2'>
            <div className="mx-4 p-2">
            <div className="flex items-center h-full">
                {
                    section && section?.map((section,index)=>(
                        <>
                        <div onClick={()=>setCurrentSection(section?.label)} key={index} >
                            <div className={`flex items-center  ${section?.label === currentSection? "bg-teal-500 text-neutral-50":"text-neutral-700" } rounded-full  relative cursor-pointer`}>
                                <div className="rounded-full flex justify-center items-center transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-teal-600">
                                    {section?.svg}
                                </div>
                                <div  className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">{section?.label}</div>
                            </div>
                        </div>
                        {
                            section?.id!==5?
                            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-teal-600"></div>
                            :null
                        }
                        </>
                    ))
                }
            </div>
                
    </div>
            </div>
        </div>
        <div className="mt-4 p-2">
        <div className='mx-2'>
            {
                currentSection === "Personal"?
                <Personal categoryOption={categoryOption} profileData={profileData} setShowToast={setShowToast} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={getUserData} nextButton={handleNext}/>
                :
                // currentSection === "Profile"? 
                // <Profile setToastMessage={setToastMessage} setShowToast={setShowToast}  toastMessage={toastMessage} getUserData={getUserData} profileData={profileData} nextButton={handleNext}/>
                // :
                currentSection==="Education"?
                <Education setToastMessage={setToastMessage} setShowToast={setShowToast}  toastMessage={toastMessage} getUserData={getUserData} profileData={profileData} nextButton={handleNext}/>
                : 
                currentSection==="Employment"?
                <Employment  setShowToast={setShowToast}  setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={getUserData} profileData={profileData} nextButton={handleNext}/>
                :
                currentSection==="Skills"?
                <Skills skillsOption={skillsOption} setShowToast={setShowToast}  setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={getUserData} profileData={profileData} nextButton={handleNext}/>
                :
                // currentSection==="Certification"?
                // <Certification setShowToast={setShowToast} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={getUserData} profileData={profileData} nextButton={handleNext}/>
                // :
                currentSection==="Project"?
                <Project setShowToast={setShowToast}  setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={getUserData} profileData={profileData} nextButton={handleNext}/>
                :null
            }
        </div>
    </div>
    </div>
  )
}

export default ProfileEdit