import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiFillPlusCircle, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { toast, ToastContainer } from 'react-toastify'
import Swal from 'sweetalert2'

const Project = ({nextButton,profileData,handlePrevious,getUserData,setShowToast,setToastMessage}) => {
  const [oldData,setOldData] = useState([])

  const [project,setProject] = useState(
    [ {
     projectTitle:"",
     projectRole:'',
     client:"",
     projectStatus:"",
     duration:"",
     projectDetails:"",
     technology:"",
     employer:'',
     keyResponsibility:'',
     location:''
    }]
   )
   
   
   useEffect(() => {
     if(profileData){
       const newData = profileData&&profileData?.projectdetail?.map((x)=>(
           {
           projectTitle:x?.project_title,
           projectRole:x?.project_role,
           client:x?.client,
           projectStatus:x?.project_status,
           duration:x?.duration,
           projectDetails:x?.details_of_project,
           technology:x?.technology,
           employer:x?.employer,
           keyResponsibility:x?.key_responsibility,
           location:x?.location
         }
       ))
       const oldData = profileData&&profileData?.projectdetail?.map((x)=>(
           {
           projectTitle:x?.project_title,
           projectRole:x?.project_role,
           client:x?.client,
           projectStatus:x?.project_status,
           duration:x?.duration,
           projectDetails:x?.details_of_project,
           technology:x?.technology,
           employer:x?.employer,
           keyResponsibility:x?.key_responsibility,
           location:x?.location
         }
       ))
      //  console.log("new data",newData)
       setOldData(oldData.length>0?oldData:[ {
        projectTitle:"",
        projectRole:'',
        client:"",
        projectStatus:"",
        duration:"",
        projectDetails:"",
        technology:"",
        employer:'',
        keyResponsibility:'',
        location:''
       }])
       setProject(newData.length>0?newData:    [ {
        projectTitle:"",
        projectRole:'',
        client:"",
        projectStatus:"",
        duration:"",
        projectDetails:"",
        technology:"",
        employer:'',
        keyResponsibility:'',
        location:''
       }])
     }
 }, [profileData])
 
 const loginUserData = JSON.parse(localStorage.getItem('Login'))
 
 const handleSubmit = async(e)=>{
   e.preventDefault()
   // dispatch(updateProfileAsync(profile))
   try {     
       const formData = new FormData()
       for(let i =0; i<project.length;i++){
         formData.append(`userprojectdetails[${i}][project_title]`,project[i]?.projectTitle!== null || undefined||""?project[i]?.projectTitle:'')
         formData.append(`userprojectdetails[${i}][project_role]`,project[i]?.projectRole!== null || undefined||""?project[i]?.projectRole:'')
         formData.append(`userprojectdetails[${i}][client]`,project[i]?.client!== null || undefined||""?project[i]?.client:'')
         formData.append(`userprojectdetails[${i}][project_status]`,project[i]?.projectStatus!== null || undefined||""?project[i]?.projectStatus:'')
         formData.append(`userprojectdetails[${i}][duration]`,project[i]?.duration!== null || undefined||""?project[i]?.duration:'')
         formData.append(`userprojectdetails[${i}][details_of_project]`,project[i]?.projectDetails!== null || undefined||""?project[i]?.projectDetails:'')
         formData.append(`userprojectdetails[${i}][technology]`,project[i]?.technology!== null || undefined||""?project[i]?.technology:'')
         formData.append(`userprojectdetails[${i}][employer]`,project[i]?.employer!== null || undefined||""?project[i]?.employer:'')
         formData.append(`userprojectdetails[${i}][key_responsibility]`,project[i]?.keyResponsibility!== null || undefined||""?project[i]?.keyResponsibility:'')
         formData.append(`userprojectdetails[${i}][location]`,project[i]?.location!== null || undefined||""?project[i]?.location:'')
       }
       if(loginUserData?.id === profileData?.id){
         const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/projectupdate`,formData,{
           headers:{
               'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
               'Accept':'application/json'
           }
         })
         const response = await request?.data
         if(response?.code === 200){
           setShowToast(true)
           setToastMessage(`${response?.message}`)
           getUserData()
         }
       }else{
        const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/projectupdate/${profileData?.id}`,formData,{
          headers:{
              'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
              'Accept':'application/json'
          }
        })
        const response = await request?.data
        if(response?.code === 200){
          // console.log('response',response)
          // response
          setShowToast(true)
          setToastMessage(`${response?.message}`)
          getUserData()
        }
        const requestSubmit = await axios.get(`${process.env.REACT_APP_API_URL}/statusupdate/${profileData?.id}`,{
          headers:{
              'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
              'Accept':'application/json'
          }
        })
        const responseSubmit = await requestSubmit?.data
        if(responseSubmit?.code === 200){
          // console.log('response',response)
          // response
          setShowToast(true)
          setToastMessage(`${responseSubmit?.message}`)
          getUserData()
        }
       }
   } catch (error) {
      //  alert(error)
       // console.log('error',error)
       console.log('error',error)
       if(error?.response?.data?.error){
           const errors = Object.values(error?.response?.data?.error)
           console.log('Errors',errors)
           errors.map((x)=>(
               // console.log("Error",x)
               toast.error(`${x}`)
           ))
       }
       if(error?.response?.data?.message){
           if(error?.response?.data?.error){
               const errors = Object.values(error?.response?.data?.error)
               console.log('Errors',errors)
               errors.map((x)=>(
                   // console.log("Error",x)
                   toast.error(`${x}`)
               ))
           }
           if(error?.response?.data?.message){
               toast.error(`${error?.response?.data?.message}`)
           }
       }
   }
 }
 
 const handleMore = (e)=>{
   e.preventDefault()
   let newField= {
    projectTitle:"",
    projectRole:'',
    client:"",
    projectStatus:"",
    duration:"",
    projectDetails:"",
    technology:"",
    employer:"",
    keyResponsibility:'',
    location:''
   }
   setProject([...project, newField])
 }
 
 const handleChange = (index,e)=>{
   let data = [...project];
   data[index][e.target.name] = e.target.value;
   setProject(data)
 }
 

 const checkPrevious = (e)=>{
  e.preventDefault()
  // console.log("check next work")
  if(JSON.stringify(oldData) === JSON.stringify(project)){
    handlePrevious()
  }else{
    Swal.fire({
      title: 'Are you sure to Move on Previous Page?',
      text: "You won't be save!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Move to Previous!'
    }).then(async(result) => {
      if (result.isConfirmed) {
        handlePrevious()
}})
  }
}

const removeFields = (index) => {
  console.log("remove field is working!")
  let data = [...project];
  data.splice(index, 1)
  setProject(data)
}
 
 
   return (
     <div>
      <ToastContainer
          position='top-center'
      />
       <form onSubmit={handleSubmit} className="grid grid-cols-2 my-3">
         {
            project?.length===0?
            <div className='flex justify-center w-full col-span-2'>
                <span className='text-lg text-center'>Please Add Project Details..!!</span>
            </div>
            :
         project && project?.map((x,index)=>(
           <div key={index} className={`col-span-2 grid grid-cols-2 ${index !== 0 ? "border-t" : ""}  border-neutral-900`}>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Project Title</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='projectTitle' required value={x?.projectTitle} onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Project Employer</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='employer' required value={x?.employer} onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Role</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='projectRole' required value={x?.projectRole}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Client Name</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='client' required value={x?.client} onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Project Status</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='projectStatus' required value={x?.projectStatus}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Key Responsibility</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='keyResponsibility' required value={x?.keyResponsibility}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Location</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='location' required value={x?.location}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">Duration</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='duration' required value={x?.duration}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-1`} >
                 <div className="font-bold h-6 mt-3after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">Technology</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <input name='technology' required value={x?.technology}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
                 </div>
             </div>
             <div className={`w-full px-2 col-span-2`} >
                 <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize">Project Details</div>
                 <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                   <textarea name='projectDetails' value={x?.projectDetails}  onChange={(e)=>handleChange(index,e)} className={`p-1 px-2 appearance-none outline-none h-24 w-full border-0 text-gray-800`}></textarea> 
                 </div>
             </div>
             {
                  index === 0 ?
                    null :
                    <div className='col-span-2 w-full mb-2 '>
                      <div onClick={() => removeFields(index)} className='cursor-pointer px-2 flex justify-center items-center '><span className='text-red-50 px-6 py-2 flex items-center justify-center bg-red-500'><RiDeleteBin6Fill /></span></div>
                    </div>
                }
           </div>
             ))
         }
         <div className='py-2 px-2 col-span-3'>
           <div className='flex justify-center'>
             <button onClick={handleMore} className='px-5 py-2 ml-1 flex items-center bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><span className='px-1'><AiFillPlusCircle/></span>Add Project</button>
           </div>
         </div>
         <div className='col-span-3 px-2 py-2  flex justify-end'>
             <button onClick={checkPrevious} className='px-5 py-2 mr-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineLeft/></button>
             <button type='submit' className='px-5 py-2 mr-1 bg-teal-700 text-neutral-50 hover:bg-teal-800 delay-75 duration-75'>Save</button>
         </div>
     </form>
     </div>
   )
}

export default Project