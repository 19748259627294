import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import MaterialReactTable from 'material-react-table'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { toast, ToastContainer } from 'react-toastify'
import { FaFileImport, FaSadTear } from 'react-icons/fa'
import FileSaver from 'file-saver'
import { useNavigate } from 'react-router-dom'

const Index = () => {
    const [courseOption,setCourseOption] = useState([])
    const [course,setCourse] = useState('')
    const navigate = useNavigate()
    
    // const [courseId,setCourseId] = useState('')
    // const [skills,setSkills] = useState([])
    // const [skillsId,setSkillsId] = useState([])
    // const [certificate,setCertificate] = useState([])
    // const [certificateId,setCertificateId] = useState([])
    // const [experience,setExperience] = useState('')
    // const [experienceId,setExperienceId] = useState('')
    // const [qualification,setQualification] = useState("")
    // const [qualificationId,setQualificationId] = useState("")
    // const [certificationOption,setCertificationOption] = useState('')
    // const [qualificationOption,setQualificationOption] = useState('')
    // const [subQualificationOption,setSubQualificationOption] = useState([])
    // const [subQualification, setSubQualification] = useState('')
    // const [subQualificationId, setSubQualificationId] = useState('')
    // const [skillsOption,setSkillsOption] = useState("")
    
     // const [courseOption,setCourseOption] = useState('')
    // const [course,setCourse] = useState('')
    // const [courseId,setCourseId] = useState('')
    
    const [rowSelection,setRowSelection] = useState({})
    const [employeeList,setEmployeeList] = useState('')
    const [showTemplateSelect,setShowTemplateSelect] = useState(false)
    const [templateList,setTemplateList] = useState('')
    const [templateId,setTemplateId] = useState('')
    const [wait,setWait] = useState(false)
    const [downloadFiles,setDownloadFiles] = useState([])

    const [isClearable, setIsClearable] = useState(true);

    const loginToken = JSON.parse(localStorage.getItem('LoginToken'))
    
    // console.log(rowSelection)

  

  const handleCertificate = (e) => {
    e.preventDefault();
    if (Object.keys(rowSelection).length > 0) {
      const selectedRows = Object.keys(rowSelection).map((id) =>
        employeeList.find((employee) => employee.id === parseInt(id))
      );
      console.log('Selected Rows:', selectedRows);
            navigate('/admin/imagetemplate',{state:selectedRows})

    } else {
      console.log('No rows selected');
    }
  };


    const [searchData, setSearchData] = useState({
        firstName: '',
        district: '',
      });

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearchData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };


    const getCourse = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/courselist`, {
          headers: {
            'Authorization': `Bearer ${loginToken}`,
            'Accept': 'application/json'
          }
        });
    
        if (response?.data?.code === 200) {
          setCourseOption(response.data.data);
        } else {
          setCourseOption([]);
        }
      } catch (error) {
        console.error('Error fetching course data:', error);
        setCourseOption([]);
      }
    }
    


   

    const getTemplateList = async()=>{
        
        try {
            const templateList = await axios.get(`${process.env.REACT_APP_API_URL}/template`,{
                headers:{
                    'Authorization':`Bearer ${loginToken}`,
                    'Accept':'application/json'
                }
            })
            const response = await templateList?.data
            if(response?.code ===200){
                setTemplateList(response?.data)
            }
        } catch (error) {
            console.log('error',error)
            error?.response?.data?.errors?.map((x)=>(
                toast.error(`${x}`)
            ))
        }
    }
    



    useEffect(()=>{
        getCourse()
    //     getQualification()
    //     getCertificate()
        getTemplateList()
    },[])

 

    const expOption = [
        {
            label:'Fresher',
            value:'0'
        },
        {
            label:'1+',
            value:'1'
        },
        {
            label:'2+',
            value:'2'
        },
        {
            label:'3+',
            value:'3'
        },
        {
            label:'4+',
            value:'4'
        },
    ]

    const columns = [
      {
        accessorKey: 'slno',
        header: 'S.No',
        size: 100,
      },
        {
          accessorKey: 'dob',
          header: 'Date Of Birth',
          size: 80,
        },
        {
          accessorKey: 'firstName',
          header: 'First Name',
          size: 120,
        // minSize: 100, //min size enforced during resizing
        // maxSize: 400, //max size enforced during resizing
        // size: 180, //medium column
        },
        {
          accessorKey: 'origin',
          header: 'C/O',
          size: 120,
        },
        {
          accessorKey: 'course',
          header: 'Course',
          size: 120,
        },
        
        {
          accessorKey: 'district',
          header: 'District',
          size: 120,
        },
        {
            accessorKey: 'remarks',
            header: 'Remark',
            size: 120,
          },
    ]

    const handleSearch = async()=>{
        const formData = new FormData()
        
        formData.append('course',course)
        formData.append('first_name', searchData.firstName);
        formData.append('district', searchData.district);
        
   
        const request = await axios.post(`${process.env.REACT_APP_API_URL}/filteruser`,formData,{
            headers:{
                'Accept':'application/json'
            }
        })
        const response = await request?.data
        console.log("filter user",response)
        if(response?.code === 200){
            setEmployeeList(response?.data?.map((x)=>{
              return {
                newID: x.emp_id ? `${x.emp_id}` : `OTH${x.id}`,
                id: x.id,
                firstName: x.first_name,
                middleName: x.middle_name,
                lastName: x.last_name,
                dob: x.dob,
                origin: x.origin,
                course: x.course?.course || '',
                remarks: x.remarks,
                district: x.district,
                slno:x.slno,
              };
            }))
        }
        if(response?.data.length===0){
            toast.info(`😞 Sorry, No Data Found..!`)
        }
        setCourse('');
        
    }

  return (
    <div>
        <ToastContainer
          position='top-center'
        />
        {
            showTemplateSelect?
            <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
            <div className="absolute bg-black opacity-90 inset-0 z-0" onClick={()=>{
                setShowTemplateSelect(false)
                setWait(false)
                }}></div>
         <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
           
         <div>
             <div className='flex justify-end' onClick={()=>{
                setShowTemplateSelect(false)
                setWait(false)
                }}>
                 <span  className='text-neutral-50 hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-2 py-1 rounded-full'>X</span>
             </div>
           
             <div  className="text-center p-3">
                 <div className='flex justify-center'>
                 <FaFileImport className='text-neutral-900' size={30}/>
                 </div>
                 <h2 className="text-xl font-bold py-4 ">Please Select Template</h2>
                 <div className='border-2 p-4 cursor-pointer border-gray-500'>
                     <div className='flex justify-center p-1 w-full'>
                     <div className='px-2 col-span-1 py-2 w-full relative z-10'>
                        <label className='p-1'>
                            Choose Template
                        </label>
                        <Select 
                        className='p-1 w-full' 
                        options={templateList&&templateList?.map((template)=>({
                            'value':template?.id,
                            'label':template?.template_name
                        }))}
                        onChange={(e)=>setTemplateId(e.value)}
                        />
                    </div>
                     </div>
                 {/* <p className="text-sm text-neutral-800 px-8">Upload CSV or XLSL file Only. </p> */}
                 </div>
             </div>
             <div className="p-3  mt-2 text-center space-x-4 md:block">
                 <button onClick={async()=>{
                    if(templateId === " " || "" || undefined || null){
                    toast.error('Please Select Template..!')
                }
                if(Object.keys(rowSelection).length===0){
                    toast.error("Please Select Atleast One Employee..!")
                }
                else{
                    try {
                        setWait(true)
                        // console.log('row selection',Object.keys(rowSelection))
                        const formData = new FormData()
                        const employeeId = Object.keys(rowSelection)
                        // console.log("Employee Id",(employeeId))
                        formData.append('id',templateId)
                        for(let i = 0; i<employeeId.length; i++){
                            formData.append(`user_id[${i}]`,employeeId[i])
                        }
                        const ocrData = await fetch(`${process.env.REACT_APP_API_URL}/ocr`,{
                            method:'post',
                            headers:{
                                'Access-Control-Allow-Origin': '*',
                                'Authorization':`Bearer ${loginToken}`,
                                'Accept':'application/json',
                            },
                            body:formData
                            
                        })
                        const response = await ocrData?.json()
                        // console.log("OCR Data response",response)
                        if(response?.code ===200){
                            setDownloadFiles(response?.data)
                            for(let i=0; i<downloadFiles.length; i++){
                                FileSaver.saveAs(`${downloadFiles[i]}`,"docs.docx")
                            }
                            // setShowTemplateSelect(false)
                        }
                        } catch (error) {
                            // console.log('error',error)
                            if(error?.response?.data?.error){
                                const errors = Object.values(error?.response?.data?.error)
                                console.log('Errors',errors)
                                errors.map((x)=>(
                                    // console.log("Error",x)
                                    toast.error(`${x}`)
                                ))
                            }
                            if(error?.response?.data?.message){
                                toast.error(`${error?.response?.data?.message}`)
                            }
                        }
                        }
                     }} 
                     className="mb-2 md:mb-0 bg-neutral-900 border border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white rounded-sm hover:shadow-lg hover:bg-neutral-700">
                     {wait?`Download Certificate`:`Save Certificate`}
                 </button>
             </div>
           </div>
         </div>
         </div>:null
        }
        <div className='w-full flex bg-white p-1 px-2'>
            <h2 className='text-2xl font-bold capitalize'>Search</h2>
            {/* <span className='text-sm font-normal text-neutral-900'>Download  ..</span> */}
        </div>
        <div className='grid grid-cols-3 bg-white rounded-sm p-1'>
      <div className='col-span-1 w-full p-1 px-2'>
        <label className='text-neutral-900 font-medium text-md'>Name</label>
        <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
        <input
          type='text'
          name='firstName'
          value={searchData.firstName}
          onChange={handleInputChange}
          className={`p-1 px-2 appearance-none  outline-none w-full border-0 text-gray-800`}
        />
        </div>
      </div>
    
      <div className='col-span-1 w-full p-1 px-2'>
                <label className='text-neutral-900 font-medium text-md'>Select Course</label>
                <Select
                    options={courseOption.map(x => ({
                      label: x?.course,
                      value: x?.id
                    }))}
                    // onChange={(e) => {
                    //   setCourse(e.value);
                    //   // setCourseId(e.value);
                    // }}
                    onChange={(e) => {
                    setCourse(e ? e.value : ''); // Update selected course
                    setIsClearable(!!e); // Update isClearable based on whether a value is selected
                  }}
                    placeholder="Search courses"
                    className='block w-full my-2 rounded-sm'
                    isClearable={isClearable}
                    styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: courseOption.length > 5 ? '200px' : 'auto', // Set maxHeight conditionally
                      overflowY: 'scroll', // Enable vertical scrolling if needed
                    }),
                  }}
                  />
                  

            </div>
            {/* <div className='col-span-1 w-full p-1'>
                <label className='text-neutral-900 font-medium text-md'>District</label>
                <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                <input
                type='text'
                name='district'
                value={searchData.district}
                onChange={handleInputChange}
                className={`p-1 px-2 appearance-none  outline-none w-full border-0 text-gray-800`}
                />
            </div>
            </div> */}
      
    </div>
     <div className='grid grid-cols-3 bg-white rounded-sm p-1'> 
           
          
            <div className='col-span-1 w-full p-1 py-9 flex justify-start'>
                <button onClick={handleSearch} className=' bg-neutral-900 border border-neutral-900 px-5 text-md py-2 rounded-sm   shadow-sm font-medium tracking-wider text-white hover:shadow-lg hover:bg-neutral-700'>Search</button>
            </div>
            <div className='col-span-6 z-0 relative py-1'>
                <MaterialReactTable 
                enableFullScreenToggle={false}
                columns={columns} data={employeeList}
                onRowSelectionChange={setRowSelection}
                getRowId={(row) => row?.id}
                enableRowSelection
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '10px',
                        sm: '11px',
                        md: '12px',
                        lg: '13px',
                        xl: '14px',
                      },
                    },
                  }}
                state={{ rowSelection }}
                options={{
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100 ],
                    toolbar: true,
                    paging: true,
                    tabLayout:"fixed"
                }}
                renderTopToolbarCustomActions={({ cell }) => (
                    Object.keys(rowSelection).length!==0?
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Button
                        // color="primary"
                        style={{
                            backgroundColor:"#171717",
                            textTransform:'capitalize'
                        }}
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        // onClick={()=>downloadCv(cell)}
                        // onClick={()=>navigate('/admin/imagetemplate', {state:cell?.row?.original})}
                        // onClick={(e)=>handleCertificate(e,cell)}
                        // onClick={() => {console.log(cell)}}
                        onClick={()=>setShowTemplateSelect(true)}
                        variant="contained"
                      >
                        {Object.keys(rowSelection).length===0?`Select Profile`:"Download Certificate"}
                      </Button>
                    </Box>:null
                    )}
                />
            </div>
        </div>
    </div>
  )
}

export default Index