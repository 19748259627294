import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from './pages/login/index'
import {routes as MapRoutes,employeeRoutes} from './lib/consts/Routes'
import DashboardLayout from "./pages/dashboard/DashboardLayout";
import Home from './pages/home/index'

function App() {
  const data = JSON.parse(localStorage.getItem('Login'))
	const role = data && data?.role
  console.log('role',role)
  return (
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/admin" element={<DashboardLayout/>}>
          <Route index element={<Home/>}/>
          {
            MapRoutes&& MapRoutes.map((x,index)=>(
            <Route path={x.path} element={x?.route} key={index}/>))
          }
      </Route>
      <Route  path="/dashboard" element={<DashboardLayout/>}>
            <Route index element={<Home/>}/>
        {
            employeeRoutes&& employeeRoutes.map((x,index)=>(
          <Route path={x.path} element={x?.route} key={index}/>
            ))
        }
      </Route>
        {/* :
      <Route path='/dashboard' element={<DashboardLayout/>}>
          <Route index element={<Home/>}/>
        </Route>
      } */}
    </Routes>
  );
}

export default App;
