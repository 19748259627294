import React from 'react'
import {
  HiOutlineViewGrid,
  HiUserGroup,
  HiUserAdd
} from 'react-icons/hi'
import {FaFileInvoice} from 'react-icons/fa'
import {ImInsertTemplate} from 'react-icons/im'
import {BsFillBookFill} from 'react-icons/bs'
import { AiOutlineUser } from 'react-icons/ai'

const localData = JSON.parse(localStorage.getItem('Login'))

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: `${localData?.role === "admin"?"/admin":"/dashboard"}`,
    role: ["admin", "employee", "other"],
    icon: <HiOutlineViewGrid />
  },
  {
    key: 'course',
    label: 'Course',
    path:'/admin/course',
    role: ["admin"],
    icon: <BsFillBookFill />
  },
  {
    key: 'addemployee',
    role: ["admin"],
    label: 'Add Student',
    path: '/admin/addemployee',
    icon: <HiUserAdd />
  },
  {
    key: 'employee',
    label: 'Students',
    path: '/admin/employee',
    role: ["admin"],
    icon: <HiUserGroup />
  },

 
  {
    key: 'template',
    role: ["admin"],
    label: 'Template',
    path: '/admin/template',
    icon: <ImInsertTemplate />
  },
  // {
  //   key: 'updateProfile',
  //   role: ["admin","employee"],
  //   label: 'Update Profile',
  //   path: `${localData?.role ==="admin"?"/admin/adminprofile":"/dashboard/profileview"}`,
  //   icon: <AiOutlineUser />
  // },
  {
    key: 'search',
    role: ["admin"],
    label: 'Search',
    path: '/admin/search',
    icon: <FaFileInvoice />
  },
]


export const DASHBOARD_SIDEBAR_SETTING_LINKS = [
  {
    id: 1,
    name: "My Profile",
    link: "myprofile",
    areaHidden: "",
  },
  {
    id: 2,
    name: "Roles & Permission",
    link: "rolesandpermission",
    areaHidden: "",
  },
]