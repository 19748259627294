import Employee from '../../pages/employee/index'
import AddEmployee from '../../pages/addemployee/Index'
import Template from '../../pages/template/Index'
import HowToUse from '../../pages/template/HowToUse'
import UpdatePassword from '../../pages/profile/UpdatePassword'
import CVGenerator from '../../pages/cvgenerator/Index'
import ProfileEdit from '../../pages/profile/ProfileEdit'
import AdminProfileView from '../../pages/profile/AdminProfileView'
import AdminProfile from '../../pages/profile/AdminProfile'
import EditForm from '../../pages/editform/Editform'
import Course from '../../pages/course/Course'
import Imagetemplate from '../../pages/imagetemplate/Imagetemplate'

export const routes =[
    {
        route:<Employee/>,
        path:'employee'
    },
    {
        route:<AddEmployee/>,
        path:'addemployee'
    },
    {
        route:<Template/>,
        path:'template'
    },
    {
        route:<HowToUse/>,
        path:'howtouse'
    },
    {
        route:<UpdatePassword/>,
        path:'updatepassword'
    },
    {
        route:<CVGenerator/>,
        path:'search'
    },
    {
        route:<ProfileEdit/>,
        path:'profile'
    },
    {
        route:<AdminProfileView/>,
        path:'profileview'
    },
    {
        route:<AdminProfile/>,
        path:'adminprofile'
    },
    {
        route:<EditForm/>,
        path:'editdetails'
    },
    {
        route:<Course />,
        path:'course'
    },
    {
        route: <Imagetemplate />,
        path:'imagetemplate'
    },
  
    
]

export const employeeRoutes = [
    {
        route:<ProfileEdit/>,
        path:'profileview'
    },
    {
        route:<UpdatePassword/>,
        path:'updatepassword'
    },
]