import React, { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'
import moment from 'moment'

import axios from 'axios';
import Select from 'react-select'


const EditForm = () => {

  const navigate = useNavigate()
  const { state } = useLocation()

  const [profileData, setProfileData] = useState('')

  const [course, setCourse] = useState()
  const [courseList , setCourseList] = useState([])

  const [initialState , setInitialState]  = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    origin: '',
    dob: '',
    course: course?.id || '',
    district: '',
    remarks: '',
    slno:'',
  });

  

  const localData = JSON.parse(localStorage.getItem('LoginToken'))
  // console.log(JSON.parse(localStorage.getItem('LoginToken')))

  
  const localLoginData = JSON.parse(localStorage.getItem('Login'))



  const findUserData = async () => {
    const request = await axios.get(`${process.env.REACT_APP_API_URL}/finduser/${state}`, {
        headers: {
            'Authorization': `Bearer ${localData}`
        }
    })
    const response = await request.data
    console.log("response", response)
    if (response?.code === 200) {
        
        setProfileData(response?.data)
    }
}


useEffect(() => {
    if (state) {
      console.log("Fetching user data...");
      findUserData();
       
    }
}, [state])

useEffect(() => {
  // console.log("profileData updated:", profileData?.profiledetail?.dob);
  if (profileData) {
    // console.log(profileData.course);
    setCourse({value: profileData.course.id, label: profileData.course.course})
    setInitialState({
      firstName: profileData?.first_name !== null ? profileData?.first_name : '',
      middleName: profileData?.middle_name !== null ? profileData?.middle_name : '',
      lastName: profileData?.last_name !== null ? profileData?.last_name : '',
      dob:moment(profileData?.dob===""||null||undefined?"":profileData?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      origin: profileData?.origin !== null ? profileData?.origin : '',
      // course: profileData?.course.course !== null ? profileData?.course.course : '',
      // course: profileData?.course?.course || '', 
      district: profileData?.district !== null ? profileData?.district : '',
      remarks: profileData?.remarks !== null ? profileData?.remarks : '',
      slno: profileData?.slno !== null ? profileData?.slno : '',
   
    });

    

    // setInitialState({
    //   firstName: profileData?.first_name || '',
    //   middleName: profileData?.middle_name || '',
    //   lastName: profileData?.last_name || '',
    //   dob: profileData?.profiledetail?.dob ? moment(profileData?.profiledetail?.dob, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
    //   // ...other fields
    // });

    
  }
}, [profileData]);

async function fetchCourseList() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/courselist`, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
        'Accept': 'application/json'
      }
    });
    const courseData = response.data;
    setCourseList(courseData);
  } catch (error) {
    console.error('Error fetching course list:', error);
  }
}

useEffect(() => {
  fetchCourseList();
}, []);




  // const [employee, setEmployee] = useState(initialState);

  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
      const formData = new FormData()
      formData.append('first_name',initialState.firstName)
      formData.append('last_name',initialState.lastName)
      formData.append('middle_name',initialState.middleName)
      formData.append('origin',initialState.origin)
      formData.append('dob',initialState.dob)
      formData.append('course',course.value)
      formData.append('district',initialState.district)
      formData.append('remarks',initialState.remarks)
      
      // formData.append('email',profileData?.email)

      // formData.append('last_name',profileData?.lastName!== null || undefined ||""?profileData?.lastName:"")
      // formData.append('dob',profileData?.profiledetail?.dob!==  null || undefined ||""?profileData?.profiledetail?.dob:"")
      if(localLoginData?.id === profileData?.id){
        const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/personalprofile`,formData,{
          headers:{
            'Authorization': `Bearer ${localData}`,
              'Accept':'application/json'
          }
        })
        const response = await request?.data
        console.log(response)
        if(response?.code === 200){
          console.log('response off',response)
          // response

          // setShowToast(true)
          
          // setToastMessage(response?.message)
          findUserData()
        }
        }else{
              const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/personalprofile/${profileData?.id}`,formData,{
                headers:{
                  'Authorization': `Bearer ${localData}`,
                    'Accept':'application/json'
                }
              })
              const response = await request?.data
              if(response?.code === 200){
                console.log('response  innn',response)
                // response

                // setShowToast(true)
                // setToastMessage(response?.message)
                toast.success(`${response?.message}`)
                findUserData()
              }
          }

      } catch (error) {
          // alert(error)
          // console.log('error',error)
          if(error?.response?.data?.error){
              const errors = Object.values(error?.response?.data?.error)
              console.log('Errors',errors)
              errors.map((x)=>(
                  // console.log("Error",x)
                  toast.error(`${x}`)
              ))
          }
          if(error?.response?.data?.message){
              if(error?.response?.data?.error){
                  const errors = Object.values(error?.response?.data?.error)
                  console.log('Errors',errors)
                  errors.map((x)=>(
                      // console.log("Error",x)
                      toast.error(`${x}`)
                  ))
              }
              if(error?.response?.data?.message){
                  toast.error(`${error?.response?.data?.message}`)
              }
          }
      }
   
  };



  return (      
    <div className='w-[98%]'>
    <ToastContainer 
      position='top-center'
      autoClose={3000}/>
   
    <div>
        <div className='mx-2'>
            <h2 className='text-2xl font-bold capitalize '>Edit Profile</h2>
            {/* <span className='text-sm font-normal text-neutral-900'>Create a New Employee Profile</span> */}
        </div>
    </div>
    <div className='w-full mt-4'>
      <div className="grid grid-cols-3">
        {/* <form onSubmit={handleSubmit} ref={regForm} className='col-span-3 grid grid-cols-3'> */}
        <form onSubmit={handleSubmit}  className='col-span-3 grid grid-cols-3'>
        <div className={`w-full px-2 col-span-1`} >
            <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize ">Serial Number</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input name='slno' value={initialState?.slno || ''} readOnly  onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none bg-slate-200 outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
        <div className={`w-full px-2 col-span-1`} >
            <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize ">First Name</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input name='firstName' value={initialState?.firstName || ''} placeholder='First Name' required  onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none  outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
        <div className={`w-full px-2 col-span-1`} >
            <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize ">Middle Name</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input name='middleName' value={initialState?.middleName || ''}  placeholder='Middle Name' onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
        <div className='w-full px-2 col-span-1' >
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Last Name</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input name='lastName' value={initialState?.lastName || ''}  placeholder='Last Name' onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
        
        <div className='w-full px-2 col-span-1' >
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Company of Origin (C/O)</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input value={initialState?.origin || ''} name='origin'  placeholder='Compony of Origin' onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
        <div className='w-full px-2 col-span-1' >
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Date of Birth</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input type='date' value={initialState?.dob || ''} name='dob'  placeholder='Date of Birth' onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
        {/* <div className='w-full px-2 col-span-1' >
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Course</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input  name='course' value={initialState?.course || ''}  placeholder='Course' onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div> */}
        <div className='w-full px-2 col-span-1'>
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Course</div>
           
              {courseList.data && course && (
                <Select
                  required
                  defaultValue={course?{value: course?.value, label: course?.label}:{}}
                  // placeholder={course&&course.course !== null||""||" "||undefined?course.course:"Please Select Category"}
                  placeholder={course?course.course:"Please Select Course"}

                  onChange={selectedOption => setCourse( selectedOption )}
                  // onChange={selectedOption => setInitialState({ ...initialState, course: selectedOption.value })}
                  // onChange={(selectedOption) =>{console.log(selectedOption)}}
                  options={courseList.data.map(course => ({ value: course.id, label: course.course }))}
                  className='w-full my-2'
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: courseList.data.length > 5 ? '200px' : 'auto', // Set maxHeight conditionally
                      overflowY: 'scroll', // Enable vertical scrolling if needed
                    }),
                  }}
                />
              )}
            
          </div>
        <div className='w-full px-2 col-span-1' >
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">District</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input value={initialState?.district || ''} name='district'  placeholder='District' onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
        <div className='w-full px-2 col-span-1' >
            <div className="font-bold h-6 after:content-['*'] after:ml-0.5 after:text-red-500 mt-3 text-gray-600 text-xs leading-8 capitalize ">Remarks</div>
            <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
              <input value={initialState?.remarks || ''} name='remarks'  placeholder='Remark' onChange={(e)=>setInitialState({...initialState,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> 
            </div>
        </div>
          <div className="mt-8">
                <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                >
                Save
                </button>
                <button
                  type="button"
                  onClick={() => navigate('/admin/employee')}
                  className="ml-6 px-4 py-2 bg-gray-300 text-gray-700 rounded-lg"
                >
                  Back
                </button>

          </div>
        </form>
      </div>
    </div>
    </div>
  );
};

export default EditForm;


