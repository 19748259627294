import Table from '../../components/profile/Table'
import axios from 'axios'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { FaEye, FaFileImport, FaUserCheck } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Swal from 'sweetalert2'



const Index = () => {
  const {state} = useLocation()
  // console.log("state",state)
  const [showImportModal,setShowImportModal] = useState(false)
  const [data,setData] = useState([])


  
  const [file,setFile]= useState("")
  const date = moment().format('MMMM Do YYYY, h:mm:ss a')


  const navigate = useNavigate()

  useEffect(()=>{
    document.title="Certificate Generator"
  },[])
 

  const localToken = JSON.parse(localStorage.getItem('LoginToken'))

  const handleProfileView = (e,cell)=>{
      e.preventDefault()
      // console.log('state cell',cell?.row?.original?.id)
      // navigate('/admin/profileview',{state:cell?.row?.original?.id})
      navigate('/admin/editdetails',{state:cell?.row?.original?.id})
  }

  

  const handleVerify = (cell)=>{
    // console.log("Dealer",cell?.row?.original)
    Swal.fire({
        title: 'Are you sure to verify employee?',
        text: "This will verify user.!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, verify it!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          try {
            const requestSubmit = await axios.get(`${process.env.REACT_APP_API_URL}/statusupdate/${cell?.row?.original?.id}`,{
              headers:{
                  'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                  'Accept':'application/json'
              }
            })
            const responseSubmit = await requestSubmit?.data
            if(responseSubmit?.code === 200){
              // console.log('response',response)
              // response
              toast.success(`${responseSubmit?.message}`)
              getData()
            }       
          } catch (error) {
              if(error?.response?.data?.errors)
              error?.response?.data?.errors?.map((x)=>(
                  toast.error(`${x}`)
              ))
          }
        }})
      }

      const handleDelete = (cell)=>{
        // console.log("Dealer",cell?.row?.original)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async(result) => {
            if (result.isConfirmed) {
                try {
                    const deleteUser = await fetch(`${process.env.REACT_APP_API_URL}/userdelete/${cell?.row?.original?.id}`,{
                      method:"post",  
                      headers:{
                            'Authorization':`Bearer  ${localToken}`,
                            'Accept':"application/json"
                        }
                    })
                    const response = await deleteUser?.json()
                    // console.log("response",response)
                    toast.success(`${response?.message}`)
                    getData()
                    // Swal.fire(
                    //   'Deleted!',
                    //   'Your file has been deleted.',
                    //   'success'
                    // )          
                } catch (error) {
                    if(error?.response?.data?.errors)
                    error?.response?.data?.errors?.map((x)=>(
                        toast.error(`${x}`)
                    ))
                }
        
    }})
      }
  

  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem('Login'))
    if(data?.role !=="admin"){
      navigate('/admin')
    }
  },[])
  

  // const downloadheading=[
  //   {
  //     accessorKey: 'id',
  //     header: 'Employee ID',
  //     size: 40,
  //   },
  //   {
  //     accessorKey: 'id',
  //     header: 'ID',
  //     size: 40,
  //   },
  //   {
  //     accessorKey: 'firstName',
  //     header: 'First Name',
  //     size: 120,
  //   },
  //   {
  //     accessorKey: 'middleName',
  //     header: 'Middle Name',
  //     size: 120,
  //   },
  //   {
  //     accessorKey: 'lastName',
  //     header: 'Last Name',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'empId',
  //     header: 'Employee Id',
  //     size: 120,
  //   },
  //   {
  //     accessorKey: 'email',
  //     header: 'Email',
  //     size: 120,
  //   },
  //   {
  //     accessorKey: 'role',
  //     header: 'Role',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'category',
  //     header: 'Category',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'phone',
  //     header: 'Phone No',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'fatherFirstName',
  //     header: 'Father First Name',
  //     size: 120,
  //   },
  //   {
  //     accessorKey: 'fatherMiddleName',
  //     header: 'Father Middle Name',
  //     size: 120,
  //   },
  //   {
  //     accessorKey: 'fatherLastName',
  //     header: 'Father Last Name',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'motherName',
  //     header: 'Mother Name',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'address',
  //     header: 'Address',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'dob',
  //     header: 'DOB',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'nationality',
  //     header: 'Nationality',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'bloodGroup',
  //     header: 'Blood Group',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'status',
  //     header: 'Status',
  //     size: 300,
  //   },
  //   {
  //     accessorKey: 'profileStatus',
  //     header: 'Profile Status',
  //     size: 300,
  //   },
  // ]

const columns = [
    {
      accessorKey: 'id',
      header: "ID",
      size: 80,
      Cell: ({ cell, row }) => <div>{parseInt(row?.id) + 1}</div>,
     
    },
    {
      accessorKey: 'slno',
      header: "S.No",
      size: 110,
     
    },
    {
      accessorKey: 'firstName',
      header: 'Name',
      size: 120,
    },
    {
      accessorKey: 'dob',
      header: 'Date of Birth',
      size: 130,
    
    },
    {
      accessorKey: 'origin',
      header: 'C/O',
      size: 120,
    },
    {
      accessorKey: 'course',
      header: 'Course',
      size: 120,
    },
    {
      accessorKey: 'district',
      header: 'District',
      size: 120,
    },
    {
      accessorKey: 'remarks',
      header: 'Remark',
      size: 140,
    },
    {
      header:"Action",
      size: 100,
      Cell:({cell})=>
        <>
        {
          state?.data?.status===0?
        <button onClick={()=>handleVerify(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><FaUserCheck size={20}/></button>:null
        }

        <button onClick={(e)=>handleProfileView(e,cell)} className='px-1 ml-1 delay-75 duration-75 text-cyan-900 hover:text-cyan-700 py-1'><FaEye size={20}/></button>
        <button onClick={()=>handleDelete(cell)} className='px-1 ml-1 delay-75 duration-75 text-red-900 hover:text-red-700 py-1'><AiFillDelete size={20}/></button>
        </>  
  },
  ];

  // console.log("file",file)

  const getData = async()=>{
      const request = await axios.get(`${process.env.REACT_APP_API_URL}/employee`,{
          headers:{
              'Authorization':`Bearer ${localToken}`,
              'Accept':"application/json"
          }
      })
      const response= await request.data
      console.log("response",response?.data)
      if(response){
        setData(response?.data?.map((x)=>{
          return {
            newID: x.emp_id ? `${x.emp_id}` : `OTH${x.id}`,
            id: x.id,
            firstName: x.first_name,
            middleName: x.middle_name,
            lastName: x.last_name,
            dob: x.dob,
            origin: x.origin,
            course: x.course?.course || '',
            remarks: x.remarks,
            district: x.district,
            slno:x.slno, 
          };
      }))
      // console.log(data);
      // response?.data?.map((x) => {
      //   setData([...data, {newID:x.emp_id?`${x?.emp_id}`:`OTH${x.id}`,id:x?.id,firstName:x.first_name,middleName:x.middle_name,lastName:x.last_name,dob:x.dob, origin:x.origin, course:x.course ,remarks:x.remarks , district:x.district}])
      // });
      }
  }

  useEffect(()=>{
      getData()
  },[])

  const filterStatusData = data && data?.filter((x)=>x?.status===state?.data?.status)
  // console.log("state status",state?.data?.status)
  // console.log("filter status data",filterStatusData)
  const filterStatusProfileData = data && data?.filter((x)=>x?.profileStatus===state?.data?.profileStatus)
  // console.log("state Profile Status",state?.data?.profileStatus)
  // console.log("filter Profile status data",filterStatusProfileData)
  const filterProfileRole = data && data?.filter(x=>x?.role===state?.data?.role)
  // console.log("state Role",state?.data?.role)
  // console.log("filter Role data",filterProfileRole)
  

return (
  <>
      <ToastContainer 
      position='top-center'
      autoClose={8000}/>
      {
        showImportModal?
    <div className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"  id="modal-id">
   	<div className="absolute bg-black opacity-90 inset-0 z-0" onClick={()=>setShowImportModal(false)}></div>
    <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
      
    <div>
        <div className='flex justify-end' onClick={()=>setShowImportModal(false)}>
            <span  className='text-neutral-50 hover:bg-red-700 cursor-pointer delay-75 duration-75 bg-red-500 px-3 py-1 rounded-full'>X</span>
        </div>
      
        <div  className="text-center p-3">
            <div className='flex justify-center'>
            <FaFileImport className='text-neutral-900' size={30}/>
            </div>
            <h2 className="text-xl font-bold py-4 ">Import File</h2>
            <div htmlFor="dropzone-file" className='border-2 p-4 cursor-pointer border-dashed border-neutral-900'>
                <div className='flex justify-center p-1'>
                    <input id="dropzone-file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" className="text-last-center" onChange={(e)=>setFile(e.target.files[0])} />  
                </div>
            <p className="text-sm text-neutral-800 px-8 ">Upload CSV or XLSX file only. </p>
            </div>
        </div>
        <div className="p-3  mt-2 text-center space-x-4 md:block">
            <button onClick={async()=>{
                if(file){
                    const formData= new FormData()
                    formData.append('import_file',file)
                    try {
                        const sendFile = await axios.post(`${process.env.REACT_APP_API_URL}/user/import`,formData,{
                            headers:{
                                'Authorization':`Bearer ${localToken}`,
                                'Content-Type': 'multipart/form-data',
                                'Accept': 'application/json'
                            }
                        })
                        const responseData = await sendFile?.data
                        // console.log('response',responseData)
                        toast.done("Data Inserted Succesfully")
                        setShowImportModal(false)
                        getData()
                        
                    } catch (error) {
                        console.log("error",error)
                        if(error?.response?.data?.error){
                          const errors = Object.values(error?.response?.data?.error)
                          console.log('Errors 1',errors)
                          errors.map((x)=>(
                              // console.log("Error",x)
                              toast.error(`${x}`)
                          ))
                        }
                        if(error?.response?.data?.message){
                            if(error?.response?.data?.error){
                                const errors = Object.values(error?.response?.data?.error)
                                console.log('Errors 2',errors)
                                errors.map((x)=>(
                                    // console.log("Error",x)
                                    toast.error(`${x}`)
                                ))
                            }
                            if(error?.response?.data?.message){
                                toast.error(`${error?.response?.data?.message}`)
                            }
                        }
                    }
                }
                else{
                  toast.error('Please select file.!')
                }
                // setShowImportModal(false)
                }} className="mb-2 md:mb-0 bg-neutral-900 border border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white  rounded-sm hover:shadow-lg hover:bg-neutral-800">
                Submit
            </button>
            <a href={require('../../assets/student.xlsx')} download={"sample-sheet"+JSON.stringify(date)} >
                <button className="mb-2 md:mb-0 bg-neutral-900 border border-neutral-900 px-5 py-2 text-sm  shadow-sm font-medium tracking-wider text-white  rounded-sm hover:shadow-lg hover:bg-neutral-800">Download Demo</button>
            </a>
        </div>
      </div>
    </div>
    </div>
:null
    }
    

  <div className='w-full p-3 rounded-sm bg-backgroundColor'>
      <div className='mx-2 '>
          <div className='flex  justify-between items-center'>
              <div>
                  <h2 className='text-heading font-bold capitalize'>Students</h2>
                  {/* <span className='text-sm font-normal text-neutral-900'>List of Employees for Future Reference..</span> */}
              </div>
              <button onClick={()=>setShowImportModal(true)} className={`px-6 hover:shadow-lg h-10 border border-neutral-900 font-normal ${showImportModal?"bg-white text-neutral-900":"bg-neutral-900 text-white"} hover:bg-neutral-800 delay-75 duration-75  rounded-sm`}>
                    Add Bulk Student
                </button>
          </div>
          <div className='w-full mt-4'>
              <Table 
              enableFullScreenToggle={false} 
              data={
                state&&state?.data?.profileStatus?filterStatusProfileData
                :
                state?.data?.status ===0 ?filterStatusData
                :
                state?.data?.role?filterProfileRole
                :
                data
              } 
                columns={columns}
                // downloadheading={downloadheading} 
                />

          </div>
      </div>
  </div>
  </>
)
}

export default Index