import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import { useLocation, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import Resume from '../../assets/a4size.jpg';

const Imagetemplate = () => {

  const navigate = useNavigate();

  const location = useLocation();
  const rowData = location.state[0];
  console.log('rowdata dd',rowData)

  const currentDate = new Date();
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const year = currentDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  

  const certificateRef = useRef(null);

  const handleBack = () => {
    navigate('/admin/search')
  }

  function formatDateToDdMmYyyy(inputDate) {
    // Parse the date string in yyyy-mm-dd format
    const parts = inputDate.split('-');
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
  
    // Create a new Date object
    const date = new Date(year, month - 1, day);
  
    // Extract day, month, and year components
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const yyyy = date.getFullYear();
  
    // Format the date as dd/mm/yyyy
    return `${dd}/${mm}/${yyyy}`;
  }

  const downloadCertificate = () => {
    // Get the certificate content as an image using html2canvas
    html2canvas(certificateRef.current).then((canvas) => {
      // Create a PDF using jsPDF and set the dimensions to A4 (210mm x 297mm)
      const pdf = new jsPDF('portrait', 'mm', 'a4');

      // Calculate the aspect ratio of the content
      const aspectRatio = 210 / 297;

      // Calculate the width and height of the PDF document
      const pdfWidth = 210; // Width of the PDF in mm (A4 width)
      const pdfHeight = pdfWidth / aspectRatio;

      // Add the image of the captured content to the PDF
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdfWidth, pdfHeight);

      // Download the PDF
      pdf.save('certificate.pdf');
    });
  };

  return (
    <div className="w-full">
      <div className="mx-2">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold capitalize">Certificate</h2>
          </div>
          <div>
          <button
            className="bg-black hover:bg-green-600 text-white px-4 py-2 rounded-lg mr-4 "
            onClick={handleBack}
          >
            Back
          </button>
       
          <button
            className="bg-black hover:bg-green-600 text-white px-4 py-2 rounded-lg"
            onClick={downloadCertificate}
          >
            Download
          </button>
          </div>
        </div>
      </div>
      <div ref={certificateRef}>
        <div id="certificate-content" className=" w-210mm h-297mm">
          <img src={Resume} alt="Resume" className="w-full h-full object-contain" />
          <div>
          <div className=" ml-34mm text-center">
            <h3>.</h3>
            <h3 className="text-2xl font-semibold mt-[-63.5rem] ml-[35rem]" id='date-container'>{formattedDate}</h3>
            <h2 className="text-2xl font-semibold mt-[1.2rem]">{rowData?.firstName} {rowData?.middleName} {rowData?.lastName} </h2>
            {/* <div className="flex justify-center mt-[2.5rem]"> */}
              <h3 className="text-2xl font-semibold ml-[-33rem] mt-[2.5rem]">{rowData?.origin}</h3>
              <h3 className="text-2xl font-semibold ml-[23rem] mt-[-1.9rem]">{formatDateToDdMmYyyy(rowData?.dob)}</h3>
            {/* </div> */}
           
              <h3 className="text-2xl font-semibold ml-[-20rem] mt-[2.5rem]">{rowData?.course}</h3>
              <h3 className="text-2xl font-semibold ml-[22rem] mt-[-2rem]">{rowData?.district}</h3>
          
            <h3 className="text-2xl font-semibold mt-[2.5rem] ml-[16rem]">{rowData?.remarks}</h3>
            {/* <h3 className="text-2xl font-semibold ">01</h3> */}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imagetemplate;
