import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import {AiFillMail} from 'react-icons/ai'
import {BsFillBookFill} from 'react-icons/bs'
import { FaPhoneAlt, FaUserAlt, FaUserCheck, FaUserFriends, FaUserTimes, FaUsers } from 'react-icons/fa'
import { HiDocument, HiDocumentDuplicate, HiUserAdd, HiUsers } from 'react-icons/hi'
import {MdPreview} from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
// import PopularProducts from '../components/PopularProducts'
import BuyerProfilePieChart from '../../components/dashboard/BuyerProfilePieChart'

const Index =()=> {

	const navigate = useNavigate()
	const [dashboardData,setDashboardData] = useState('')
	const getDashboardData = async()=>{
		const request = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`,{
			headers:{
				'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
				'Accept':'application/json'
			}
		})
		const response = await request.data
		console.log('dashbord',response?.data)
		if(response?.code ===200){
			// console.log('dashbord',response?.data)
			setDashboardData(response?.data)
		}
	}
	
	useEffect(()=>{
		getDashboardData()
	},[])


  // total employees calculation
  const totalEmployees = dashboardData&& dashboardData?.employee+dashboardData?.other

  const data =[
    {
      logo:<FaUsers className="lg:text-6xl opacity-30"/>,
      data:dashboardData?.student === null || undefined|| ""? "":dashboardData?.student,
      title:"All Student's",
      color:"bg-sky-500",
      path:"/admin/employee",
      cardBackgroundColor:'bg-sky-300 hover:bg-cyan-800 duration-100 delay-100',
      passState:{status:null},
      buttonColor:'bg-sky-600 delay-100 duration-100',
      buttonHoverColor:'hover:bg-cyan-500',
    },
    {
      logo:<BsFillBookFill className="lg:text-6xl opacity-30"/>,
      data:dashboardData?.course === null || undefined|| ""? "":dashboardData?.course,
      title:"Course's",
      color:"bg-orange-600",
      path:"/admin/course",
      passState:{role:'employee'},
      buttonColor:'bg-sky-600 delay-100 duration-100',
      buttonHoverColor:'hover:bg-cyan-500',
      cardBackgroundColor:'bg-sky-300 hover:bg-cyan-800 duration-100 delay-100',
    },
    
   
  ]

	const localData = JSON.parse(localStorage.getItem('Login'))
	console.log("data", data)
	
	if(localData&&localData?.role === "admin")
	{
		return (
			<div className="lg:px-4 lg:py-0 py-2 px-2 w-full">
			<div className="grid  grid-cols-2 lg:gap-4 gap-2 lg:grid-cols-4">
			  {data &&
				data.map((dashboard, index) => (
				  <div
					key={index}
					className={`w-full flex shadow flex-col h-36 ${dashboard?.cardBackgroundColor} rounded-lg`}
				  >
					<div className="lg:p-4 group p-2 group-hover:text-gray-50 flex-1 items-center justify-between">
					  <div className="lg:flex items-center justify-between">
						<div className="text-gray-900 lg:text-left text-center h-24">
						  <span className="block lg:text-4xl group-hover:text-gray-50 lg:text-left  text-center text-4xl font-bold py-1">
							{dashboard.data}
						  </span>
						  <span className="text-md text-gray-900 group-hover:text-gray-200 font-normal  lg:py-1 py-0 lg:text-left text-center">{dashboard.title}</span>
						</div>
						<div className="lg:block hidden cursor-pointer group-hover:text-white">{dashboard.logo}</div>
					  </div>
					</div>
					<button
						onClick={()=>navigate(dashboard?.path,{state:{data:dashboard?.passState}})}
					  className={`flex rounded-b-lg text-white justify-center ${dashboard.buttonColor} ${dashboard.buttonHoverColor}  w-full items-center`}
					>
					  <span className="px-2">More info </span>
					  <span>
						<BsArrowRightCircleFill />
					  </span>
					</button>
				  </div>
				))}
			</div>
			<div className="flex flex-row py-4 justify-center w-full">
				{/* <TransactionChart /> */}
				{/* <BuyerProfilePieChart /> */}
			</div>
			{/* <div className="flex flex-row gap-4 w-full">
				<RecentOrders />
				<PopularProducts />
			</div> */}
		</div>
	)
	}
	else
	{
		return(
	<div className='grid grid-cols-3'>
		<div className='p-2 bg-gray-50 border rounded-sm border-gray-200 col-span-1'>
			<h2 className='font-normal text-base'>Profile</h2>
			<div>
				<div className='flex justify-center'>
				<img className="h-20 w-20 object-cover rounded-full" src={localData&&localData?.profile} alt="Profile Image" />
				</div>
				<div className='p-2 w-full flex'>
					<span className='px-1'><FaUserAlt/></span><span className='px-1'>{localData&&localData?.first_name !== null? localData?.first_name:"" + " "+ localData&&localData?.middle_name !==null?localData?.middle_name:"" + " " + localData&&localData?.last_name !== null ? localData?.last_name:""}</span>
				</div>
				<div className='p-2 flex w-full'>
					<span className='px-1'><AiFillMail/></span><span className='px-1'>{localData&&localData?.email}</span>
				</div>
				<div className='p-2 w-full flex '>
					<span className='px-1'><FaPhoneAlt/></span><span className='px-1'>{localData&&localData?.phone_no}</span>
				</div>
			</div>
			<div className='w-full flex justify-center'>
				<button onClick={()=>navigate('/dashboard/profileview')} className='px-5 py-2 rounded-sm delay-75 duration-75 bg-neutral-800 hover:bg-neutral-900 text-white'>Edit Profile</button>
			</div>
		</div>
	</div>
		)
	}
}

export default Index