import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FaPencilRuler } from 'react-icons/fa'
import { FiBarChart2, FiClipboard, FiUser, FiUserPlus } from 'react-icons/fi'
import { MdWork } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import AdminPersonal from './AdminPersonal'

const AdminProfile = () => {
    const {state} = useLocation()
    console.log('state',state)
    const [profileData,setProfileData] = useState('')
    const [toastMessage,setToastMessage] = useState('')



    const localData = JSON.parse(localStorage.getItem('LoginToken'))

    const getUserData = async()=>{
        const request = await axios.get(`${process.env.REACT_APP_API_URL}/userdetails`,{
            headers:{
                'Authorization':`Bearer ${localData}`
            }
        })
        const response = await request.data
        if(response?.code ===200){
            localStorage.removeItem('Login')
            setProfileData(response?.data[0])
            const newData = {
                email:response?.data[0]?.email,
                emp_id:response?.data[0]?.emp_id,
                first_name:response?.data[0]?.first_name,
                id:response?.data[0]?.id,
                job_profile:response?.data[0]?.job_profile,
                last_name:response?.data[0]?.last_name,
                middle_name:response?.data[0]?.middle_name,
                phone_no:response?.data[0]?.phone_no,
                profile:response?.data[0]?.profile,
                role:response?.data[0]?.role,
            }
            localStorage.setItem('Login',JSON.stringify(newData))
        }
    }




    
    // console.log("currention section",currentSection)
    // console.log("profile data",profileData)
    console.log("Profile Data in Edit Profile",profileData)

    useEffect(()=>{
        getUserData()
    },[state])



  return (
    <div className='w-[98%]'>
        <div>
            <div className='mx-2'>
                <h2 className='text-2xl font-bold capitalize'>Profile</h2>
                {/* <span className='text-sm font-normal text-neutral-900'>Edit Profile of a Candidate</span> */}
            </div>
            <div className='w-full mt-4'>
            <div className="mx-4 p-4">
            <div className="flex items-center h-full">
            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-teal-600"></div>
                <div >
                    <div className={`flex items-center  bg-teal-500 text-neutral-50 rounded-full  relative cursor-pointer`}>
                        <div className="rounded-full flex justify-center items-center transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-teal-600">
                            <FiUser/>
                        </div>
                        <div  className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-teal-600">Personal</div>
                    </div>
                </div>
                <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-teal-600"></div>
            </div>
                
    </div>
        </div>
        </div>
        <div className="mt-8 p-4">
        <div className='mx-2'>
            <AdminPersonal profileData={profileData} setToastMessage={setToastMessage} toastMessage={toastMessage} getUserData={getUserData}/>
        </div>
    </div>
    </div>
  )
}

export default AdminProfile