import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { toast, ToastContainer } from 'react-toastify'
import Select from 'react-select'
import moment from 'moment'
import Swal from 'sweetalert2'

const Personal = ({nextButton,profileData,getUserData,handlePrevious,setShowToast,setToastMessage}) => {
    // console.log('certification',certification)

    const [oldPersonal,setOldPersonal]=useState("")
    const [oldProfile,setOldProfile] = useState('')

    const [perSonal,setPersonal] = useState({
        firstName:"",
        middleName:'',
        lastName:"",
        email:"",
        employeeId:"",
        phone:"",
        profile:"",
        file:null
    })

    const [profile,setProfile] = useState({
        fatherFirstName:"",
        fatherMiddleName:'',
        fatherLastName:"",
        motherName:"",
        address:"",
        currentAddress:"",
        dob:"",
        nationality:"",
        bloodGroup:""
    })

    const localLoginData = JSON.parse(localStorage.getItem('Login'))
    // console.log('login data',localLoginData?.id)

    console.log("profile data",profileData)
    
    useEffect(() => {
      if(profileData){
        setPersonal({
            firstName:profileData?.first_name !== null || "null"? profileData?.first_name:"" ,
            middleName:profileData?.middle_name !== null || "null"? profileData?.middle_name:"",
            lastName:profileData?.last_name !== null || "null"? profileData?.last_name:"",
            email:profileData?.email !== null || "null"? profileData?.email:"",
            employeeId:profileData?.emp_id!== null || "null"?profileData?.emp_id:"",
            phone:profileData?.phone_no!== null || "null"?profileData?.phone_no:"",
            profile:profileData?.profile!== null || "null"?profileData?.profile:""
        })
        setOldPersonal({
            firstName:profileData?.first_name !== null || "null"? profileData?.first_name:"" ,
            middleName:profileData?.middle_name !== null || "null"? profileData?.middle_name:"",
            lastName:profileData?.last_name !== null || "null"? profileData?.last_name:"",
            email:profileData?.email !== null || "null"? profileData?.email:"",
            employeeId:profileData?.emp_id!== null || "null"?profileData?.emp_id:"",
            phone:profileData?.phone_no!== null || "null"?profileData?.phone_no:"",
            profile:profileData?.profile!== null || "null"?profileData?.profile:""
        })
        setProfile({
            fatherFirstName:profileData?.profiledetail?.father_first_name === ""|| null || undefined?"":profileData?.profiledetail?.father_first_name,
            fatherMiddleName:profileData?.profiledetail?.father_middle_name === ""|| null ||undefined?"":profileData?.profiledetail?.father_middle_name,
            fatherLastName:profileData?.profiledetail?.father_last_name === ""||null||undefined?"":profileData?.profiledetail?.father_last_name,
            motherName:profileData?.profiledetail?.mother_name!== null || "null"?profileData?.profiledetail?.mother_name:"",
            address:profileData?.profiledetail?.address === ""|| null || undefined?"":profileData?.profiledetail?.address,
            currentAddress:profileData?.profiledetail?.present_address === ""||null||undefined?"":profileData?.profiledetail?.present_address,
            bloodGroup:profileData?.profiledetail?.blood_group === ""||null ||undefined? "":profileData?.profiledetail?.blood_group,
            dob:moment(profileData?.profiledetail?.dob===""||null||undefined?"":profileData?.profiledetail?.dob).format('YYYY-MM-DD'),
            nationality:profileData?.profiledetail?.nationality===""||null||undefined?"":profileData?.profiledetail?.nationality,
        })
        setOldProfile({
            fatherFirstName:profileData?.profiledetail?.father_first_name === ""|| null || undefined?"":profileData?.profiledetail?.father_first_name,
            fatherMiddleName:profileData?.profiledetail?.father_middle_name === ""|| null ||undefined?"":profileData?.profiledetail?.father_middle_name,
            fatherLastName:profileData?.profiledetail?.father_last_name === ""||null||undefined?"":profileData?.profiledetail?.father_last_name,
            currentAddress:profileData?.profiledetail?.present_address === ""||null||undefined?"":profileData?.profiledetail?.present_address,
            motherName:profileData?.profiledetail?.mother_name!== null || "null"?profileData?.profiledetail?.mother_name:"",
            address:profileData?.profiledetail?.address === ""|| null || undefined?"":profileData?.profiledetail?.address,
            bloodGroup:profileData?.profiledetail?.blood_group === ""||null ||undefined? "":profileData?.profiledetail?.blood_group,
            dob:moment(profileData?.profiledetail?.dob===""||null||undefined?"":profileData?.profiledetail?.dob).format('YYYY-MM-DD'),
            nationality:profileData?.profiledetail?.nationality===""||null||undefined?"":profileData?.profiledetail?.nationality,
        })
      }
    }, [profileData])
    
    const personalProfile = [
        {
            label:"First Name",
            type:"text",
            id:"firstName",
            name:"firstName",
            key:perSonal?.firstName,
            required:true,
            value:perSonal?.firstName,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Middle Name",
            type:"text",
            id:"middleName",
            name:"middleName",
            key:perSonal?.middleName,
            required:false,
            value:perSonal?.firstName,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Last Name",
            type:"text",
            id:"lastName",
            name:"lastName",
            key:perSonal?.lastName,
            required:true,
            value:perSonal?.lastName,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Email",
            type:"email",
            id:"email",
            name:"email",
            key:perSonal?.email,
            required:true,
            value:perSonal?.email,
            columnLength:"col-span-1",
            disable:false,
            maxlength:50,
        },
        {
            label:"Employee ID",
            type:"text",
            id:"employeeId",
            name:"employeeId",
            key:perSonal?.employeeId,
            required:false,
            value:perSonal?.employeeId,
            columnLength:"col-span-1",
            disable:true,
            maxlength:100,
        },
    ]



    // console.log("category option",categoryOptions)
    


    const handleSubmit = async(e)=>{
        e.preventDefault()
        // dispatch(updateProfileAsync(profile))
        try {     
            const formData = new FormData()
            formData.append('email',perSonal?.email!== null || undefined ||""?perSonal?.email:"")
            formData.append('emp_id',perSonal?.employeeId!== null || undefined ||""?perSonal?.employeeId:"")
            formData.append('first_name',perSonal?.firstName!== null || undefined ||""?perSonal?.firstName:"")
            formData.append('middle_name',perSonal?.middleName!== null || undefined ||""?perSonal?.middleName:"")
            formData.append('last_name',perSonal?.lastName!== null || undefined ||""?perSonal?.lastName:"")
            formData.append('phone_no',perSonal?.phone!== null || undefined ||""?perSonal?.phone:"")
            formData.append('profile',profile?.file)
            formData.append('address',profile?.address!== null || undefined ||""?profile?.address:"")
            formData.append('dob',profile?.dob!== null || undefined ||""?profile?.dob:'')
            formData.append('father_first_name',profile?.fatherFirstName!== null || undefined ||""?profile?.fatherFirstName:"")
            formData.append('father_middle_name',profile?.fatherMiddleName!== null || undefined ||""?profile?.fatherMiddleName:"")
            formData.append('father_last_name',profile?.fatherLastName!== null || undefined ||""?profile?.fatherLastName:"")
            formData.append('nationality',profile?.nationality!== null || undefined ||""?profile?.nationality:'')
            formData.append('blood_group',profile?.bloodGroup!== null || undefined ||""?profile?.bloodGroup:"")
            formData.append('mother_name',profile?.motherName!== null || undefined ||""?profile?.motherName:'')
            formData.append('present_address',profile?.motherName!== null || undefined ||""?profile?.currentAddress:'')
            if(localLoginData?.id === profileData?.id){
                const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/personalprofile`,formData,{
                  headers:{
                      'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                      'Accept':'application/json'
                  }
                })
                const response = await request?.data
                if(response?.code === 200){
                  // console.log('response',response)
                  // response
                  setShowToast(true)
                  setToastMessage(response?.message)
                  getUserData()
                }
            }else{
                const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/personalprofile/${profileData?.id}`,formData,{
                  headers:{
                      'Authorization':`Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
                      'Accept':'application/json'
                  }
                })
                const response = await request?.data
                if(response?.code === 200){
                  // console.log('response',response)
                  // response
                  setShowToast(true)
                  setToastMessage(response?.message)
                  getUserData()
                }
            }

        } catch (error) {
            // alert(error)
            // console.log('error',error)
            if(error?.response?.data?.error){
                const errors = Object.values(error?.response?.data?.error)
                console.log('Errors',errors)
                errors.map((x)=>(
                    // console.log("Error",x)
                    toast.error(`${x}`)
                ))
            }
            if(error?.response?.data?.message){
                if(error?.response?.data?.error){
                    const errors = Object.values(error?.response?.data?.error)
                    console.log('Errors',errors)
                    errors.map((x)=>(
                        // console.log("Error",x)
                        toast.error(`${x}`)
                    ))
                }
                if(error?.response?.data?.message){
                    toast.error(`${error?.response?.data?.message}`)
                }
            }
        }
    }

    const profileInput = [
        {
            label:"Father First Name",
            type:"text",
            name:"fatherFirstName",
            key:profile?.fatherFirstName,
            value:profile?.fatherFirstName,
            required:true,
            // value:userData?.first_name,
            columnLength:"col-span-1"
        },
        {
            label:"Father Middle Name",
            type:"text",
            name:"fatherMiddleName",
            key:profile?.fatherMiddleName,
            value:profile?.fatherMiddleName,
            required:false,
            // value:userData?.middle_name,
            columnLength:"col-span-1"
        },
        {
            label:"Father Last Name",
            type:"text",
            name:"fatherLastName",
            key:profile?.fatherLastName,
            value:profile?.fatherLastName,
            required:true,
            // value:userData?.last_name,
            columnLength:"col-span-1"
        },
        {
            label:"Mother Name",
            type:"text",
            id:"motherName",
            name:"motherName",
            key:profile?.motherName,
            required:true,
            value:profile?.motherName,
            columnLength:"col-span-1",
        },
        {
            label:"Address",
            type:"text",
            name:"address",
            key:profile?.address,
            value:profile?.address,
            required:true,
            // value:userData?.email,
            columnLength:"col-span-1"
        },
        {
            label:"Current Address",
            type:"text",
            name:"currentAddress",
            key:profile?.currentAddress,
            value:profile?.currentAddress,
            required:true,
            // value:userData?.email,
            columnLength:"col-span-1"
        },
        {
            label:"Date of Birth",
            type:"date",
            name:"dob",
            max:"2000-01-01",
            key:profile?.dob,
            value:profile?.dob,
            required:true,
            // value:userData?.emp_id,
            columnLength:"col-span-1"
        },
        {
            label:"Nationality",
            type:"text",
            name:"nationality",
            key:profile?.nationality,
            value:profile?.nationality,
            required:false,
            // value:userData?.phone_no,
            columnLength:"col-span-1"
        },
        {
            label:"Blood Group",
            type:"text",
            name:"bloodGroup",
            key:profile?.bloodGroup,
            value:profile?.bloodGroup,
            required:false,
            // value:userData?.phone_no,
            columnLength:"col-span-1"
        },
    ]

  
  const checkNext = (e)=>{
    e.preventDefault()
    // console.log("check next work")
    if(JSON.stringify(oldPersonal) !== JSON.stringify(perSonal) && JSON.stringify(oldProfile) !== JSON.stringify(profile)){
      
      Swal.fire({
        title: 'Are you sure to Move on Next Page?',
        text: "You won't be save!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Move to Next!'
      }).then(async(result) => {
        if (result.isConfirmed) {
          nextButton()
  }})
    }else{
        nextButton()
    }
  }

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
    }
  


    // const customSearch = certificationOptions && certificationOptions?.filter(x=>certification?.filter(y=>y?.label.toLowerCase().includes(x?.label.toLowerCase())))
    // x=>certificationOptions && certificationOptions?.filter(y=>y?.label?.includes(x?.name))
    // console.log('customSearch',customSearch)
    // console.log("Profile", profile)

  return (
    <div className='w-full h-full'>
        <ToastContainer
          position='top-center'
        />
    <form onSubmit={handleSubmit} className="grid grid-cols-3">
        {
        personalProfile && personalProfile?.map((x,index)=>(
                <div className={`w-full px-2 ${x?.columnLength}`} key={index}>
                    <div className={`font-bold h-6 mt-3 text-gray-600 text-xs ${x?.required?"after:content-['*'] after:ml-0.5 after:text-red-500":""} leading-8 capitalize`}>{x?.label}</div>
                    <div className={`${x.disable?"bg-gray-200":"bg-white"}  my-2 p-1 flex border border-gray-200 rounded`}>
                        <input disabled={x?.disable} maxLength={x?.maxlength} value={x?.key} placeholder={x?.label} id={x?.id} name={x?.name} type={x?.type} required={x?.required} onChange={(e)=>setPersonal({...perSonal,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800 ${x.disable?"bg-gray-200 cursor-pointer":""}`}/> </div>
                </div>
            ))
        }
                <div className={`w-full px-2 col-span-1`}>
                    <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize">Phone</div>
                    <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                        <input maxLength={10} onInput={maxLengthCheck} value={perSonal?.phone} placeholder={"Enter Phone Number"} id="phone" name="phone" type={'number'} required={true} minLength={10} onChange={(e)=>setPersonal({...perSonal,[e.target.name]:e.target.value})} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`}/> </div>
                </div>
        <div className='col-span-3 grid-cols-3 grid w-full'>
        {
        profileInput && profileInput?.map((x,index)=>(
                <div className={`w-full px-2 ${x.columnLength}`} key={index}>
                    <div className={`font-bold h-6 mt-3 text-gray-600 text-xs ${x?.required?"after:content-['*'] after:ml-0.5 after:text-red-500":""} leading-8 capitalize`}>{x.label}</div>
                    <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
                        <input value={x.value} name={x.name} max={x?.max} onChange={(e)=>setProfile({...profile,[e.target.name]:e.target.value})} placeholder={x.label} type={x.type} required={x.required} className="p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800"/> </div>
                </div>
            ))
        }
        <div className='col-span-1'>
                <div className="shrink-0 flex py-3 justify-center">
                    <img className="h-16 w-16 object-cover rounded-full" src={perSonal?.profile} />
                </div>
                <label className="block">
                    <div className='flex justify-center'>
                    <span className="sr-only">Choose profile photo</span>
                    <input type="file" className="block px-2 text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100
                    "
                    name='file'
                    accept='image/x-png,image/gif,image/jpeg'
                    onChange={(e)=>setPersonal({...perSonal,[e.target.name]:e.target.files[0]})}
                    />
                    </div>
                </label>
            </div>
        </div>


        <div className='col-span-3 px-2 py-2  flex justify-end'>
            <button type='submit' className='px-5 py-2 mx-1 bg-teal-700 text-neutral-50 hover:bg-teal-800 delay-75 duration-75'>Save</button>
            <button onClick={checkNext} className='px-5 py-2 ml-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineRight/></button>
        </div>
    </form>
    </div>
  )
}

export default Personal