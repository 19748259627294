import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AiFillPlusCircle, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { toast, ToastContainer } from 'react-toastify'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { RiDeleteBin6Fill } from 'react-icons/ri'

const Education = ({ nextButton, profileData, handlePrevious, getUserData, setShowToast, setToastMessage }) => {
  const [qualificationOption, setQualificationOption] = useState('')
  // const [qualification,setQualification] = useState('')
  const [qualificationId, setQualificationId] = useState('')
  const [subQualificationOption, setSubQualificationOption] = useState('')
  const [certificationList, setCertificationList] = useState("")
  const [certification, setCertification] = useState([])
  const [oldCertification, setOldCertification] = useState([])
  const [oldData, setOldData] = useState([])


  const [education, setEducation] = useState([{
    qualification: "",
    discipline: '',
    description: "",
    yearOfPassing: "",
    percentage: "",
  }])

  const loginUserData = JSON.parse(localStorage.getItem('Login'))


  useEffect(() => {
    if (profileData) {
      const newData = profileData?.education && profileData?.education?.map((x) => (
        {
          qualification: x?.qualification,
          discipline: x?.discipline,
          description: x?.description,
          yearOfPassing: x?.year_of_passing,
          percentage: x?.percentage,
        }
      ))
      const oldData = profileData?.education && profileData?.education?.map((x) => (
        {
          qualification: x?.qualification,
          discipline: x?.discipline,
          description: x?.description,
          yearOfPassing: x?.year_of_passing,
          percentage: x?.percentage,
        }
      ))
      console.log("new data", newData)
      setOldData(oldData.length>0?oldData:[{
        qualification: "",
        discipline: '',
        description: "",
        yearOfPassing: "",
        percentage: "",
      }])
      setEducation(newData.length > 0 ? newData : [{
        qualification: "",
        discipline: '',
        description: "",
        yearOfPassing: "",
        percentage: "",
      }])
      setOldCertification(profileData?.certificationdetails.map(x => ({ label: x?.certification_code })))
      setCertification(profileData?.certificationdetails.map(x => ({ label: x?.certification_code })))
    }
  }, [profileData])

  console.log("This is education", education)

  const handleSubmit = async (e) => {
    e.preventDefault()
    // dispatch(updateProfileAsync(profile))
    try {
      const formData = new FormData()
      for (let i = 0; i < education.length; i++) {
        formData.append(`educations[${i}][year_of_passing]`, education[i]?.yearOfPassing)
        formData.append(`educations[${i}][percentage]`, education[i]?.percentage)
        formData.append(`educations[${i}][qualification]`, education[i]?.qualification)
        formData.append(`educations[${i}][description]`, education[i]?.description)
        formData.append(`educations[${i}][discipline]`, education[i]?.discipline)
        // formData.append(`educations[${i}][institute]`, education[i]?.discipline)
        for (let i = 0; i < certification.length; i++) {
          formData.append(`certifications[${i}][certification_code]`, certification[i]?.label)
        }
      }
      if (loginUserData?.id === profileData?.id) {
        const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/edicationupdate`, formData, {
          headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
            'Accept': 'application/json'
          }
        })
        const response = await request?.data
        if (response?.code === 200) {
          // console.log('response',response)
          // response
          setShowToast(true)
          setToastMessage(`${response?.message}`)
          getUserData()
        }
      } else {
        const request = await axios.postForm(`${process.env.REACT_APP_API_URL}/edicationupdate/${profileData?.id}`, formData, {
          headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('LoginToken'))}`,
            'Accept': 'application/json'
          }
        })
        const response = await request?.data
        if (response?.code === 200) {
          // console.log('response',response)
          // response
          setShowToast(true)
          setToastMessage(`${response?.message}`)
          getUserData()
        }
      }
    } catch (error) {
      // alert(error)
      // console.log('error',error)
      console.log('error', error)
      if (error?.response?.data?.error) {
        const errors = Object.values(error?.response?.data?.error)
        console.log('Errors', errors)
        errors.map((x) => (
          // console.log("Error",x)
          toast.error(`${x}`)
        ))
      }
      if (error?.response?.data?.message) {
        if (error?.response?.data?.error) {
          const errors = Object.values(error?.response?.data?.error)
          console.log('Errors', errors)
          errors.map((x) => (
            // console.log("Error",x)
            toast.error(`${x}`)
          ))
        }
        if (error?.response?.data?.message) {
          toast.error(`${error?.response?.data?.message}`)
        }
      }
    }
  }

  const handleMore = (e) => {
    e.preventDefault()
    let newField = {
      qualification: "",
      discipline: '',
      description: "",
      yearOfPassing: "",
      percentage: "",
    }
    setEducation([...education, newField])
  }

  const handleChange = (index, e) => {
    let data = [...education];
    data[index][e.target.name] = e.target.value;
    setEducation(data)
  }

  const handleSelectChange = (index, e, { nothing }) => {
    let data = [...education]
    data[index][nothing] = e.label
    setEducation(data)
    setQualificationId(e.value)
  }


  const getQualification = async () => {
    const request = await axios.get(`https://stagingcipl.ciplcrm.org.in/api/admin/qualifications`)
    const response = await request?.data
    console.log("response of category list",response)
    if (response?.code === 200) {
      setQualificationOption(response?.data)
    }
  }

  const getSubQualification = async () => {
    const formData = new FormData()
    formData.append('qualification_id', qualificationId)
    const request = await axios.post(`https://stagingcipl.ciplcrm.org.in/api/admin/subqualifications/search`, formData)
    const response = await request?.data
    console.log("response of skills list", response)
    if (response?.code === 200) {
      setSubQualificationOption(response?.data)
    }
  }

  useEffect(() => {
    if (qualificationId !== "") {
      getSubQualification()
    }
  }, [qualificationId])

  useEffect(() => {
    getQualification()
  }, [])

  const qualificationOptions = qualificationOption && qualificationOption?.map(x => ({
    'label': x?.name,
    'value': x?.id
  }))



  const getCertfication = async () => {
    const request = await axios.get(`https://stagingcipl.ciplcrm.org.in/api/admin/certifications`)
    const response = await request?.data
    console.log('response certification',response)
    if (response?.code === 200) {
      setCertificationList(response?.data.map((x) => ({
        'label': x?.name,
        'value': x?.id
      })))
    }
  }


  // const customSearch = certification && certification?.filter(x=>certificationList&&certificationList?.filter(y=>y?.label.includes(x?.certification_code)))

  const customSearch = certification && certification?.filter((x) => certificationList && certificationList?.filter(y => y?.label?.includes(x?.label)))

  useEffect(() => {
    getCertfication()
    // getCategories()
  }, [])

  console.log("certification", certification)


  const checkPrevious = (e) => {
    e.preventDefault()
    console.log("check previuos work")
    if (JSON.stringify(oldData) === JSON.stringify(education) && JSON.stringify(certification) === JSON.stringify(oldCertification)) {
      console.log("It work all are same")
      handlePrevious()
    } else {
      Swal.fire({
        title: 'Are you sure to Move on Previous Page?',
        text: "Unsaved!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Move to Previous!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          handlePrevious()
        }
      })
    }
  }

  const checkNext = (e) => {
    e.preventDefault()
    console.log("check next work")
    if (JSON.stringify(oldData) === JSON.stringify(education) && JSON.stringify(certification) === JSON.stringify(oldCertification)) {
      console.log("Click on next works")
      nextButton()
    } else {
      Swal.fire({
        title: 'Are you sure to Move on Next Page?',
        text: "Unsaved!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Move to Next!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          nextButton()
        }
      })
    }
  }

  const removeFields = (index) => {
    console.log("remove field is working!")
    let data = [...education];
    data.splice(index, 1)
    setEducation(data)
  }

  return (
    <div>
      <ToastContainer
          position='top-center'
       />
      <form onSubmit={handleSubmit} className="grid w-full grid-cols-4 my-3">
        <div className={`w-full px-2 col-span-4`} >
          <div className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 capitalize">Certifcation</div>
          <div className={`bg-white flex border border-gray-200 rounded mt-2`}>
            <Select
              required
              value={customSearch}
              options={certificationList}
              isMulti
              className='w-full'
              onChange={(e) => setCertification(e.map(x => (x)))}
            />
          </div>
        </div>
        {
          education && education?.map((x, index) => {
            return (
              <div className={`grid grid-cols-4 col-span-4  w-full ${index !== 0 ? "border-t" : ""}  border-neutral-900`} key={index}>
                <div className='grid grid-cols-6 col-span-4 w-full '>
                  <div className={`w-full px-2 py-3 col-span-2`} >
                    <label className="font-bold h-6 mt-2 text-gray-600 after:content-['*'] after:ml-0.5 after:text-red-500 text-xs leading-8 capitalize">Select Qualification</label>
                    <div className={`bg-white flex border border-gray-200 rounded`}>
                      <Select
                        options={qualificationOptions}
                        onChange={(e) => {
                          handleSelectChange(index, e, { nothing: "qualification" })
                        }}
                        placeholder={`${x?.qualification !== "" ? x?.qualification : "Search Qualification"}`}
                        className='block w-full  rounded-sm'
                      />
                    </div>
                  </div>
                  <div className={`w-full px-2 col-span-2`} >
                    <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">Discipline</div>
                    <div className={`bg-white my-2 flex border border-gray-200 rounded`}>
                      <Select
                        options={
                          qualificationId ?
                            subQualificationOption && subQualificationOption?.map(x => ({
                              'label': x?.name,
                              'value': x?.id
                            })) : [{ 'label': "Please select qualification", 'value': '' }]
                        }
                        onChange={(e) => {
                          handleSelectChange(index, e, { nothing: "discipline" })
                        }}
                        placeholder={`${x?.discipline !== "" ? x?.discipline : "Search Discipline"}`}
                        className='block w-full  rounded-sm'
                      />
                    </div>
                  </div>
                  <div className={`w-full px-2 col-span-2`} >
                    <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">Description</div>
                    <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                      <input name='description' required value={x.description} onChange={(e) => handleChange(index, e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`} />
                    </div>
                  </div>
                  <div className={`w-full px-2 col-span-2`} >
                    <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">Year of Passing</div>
                    <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                      <input name='yearOfPassing' required min="2000-01-02" value={x.yearOfPassing} type={'date'} onChange={(e) => handleChange(index, e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`} />
                    </div>
                  </div>
                  <div className={`w-full px-2 col-span-2`} >
                    <div className="font-bold h-6 mt-3 after:content-['*'] after:ml-0.5 after:text-red-500 text-gray-600 text-xs leading-8 capitalize">Percentage</div>
                    <div className={`bg-white  my-2 p-1 flex border border-gray-200 rounded`}>
                      <input name='percentage' required value={x.percentage} onChange={(e) => handleChange(index, e)} className={`p-1 px-2 appearance-none outline-none w-full border-0 text-gray-800`} />
                    </div>
                  </div>
                {
                  index === 0 ?
                    null :
                    <div className='col-span-2 w-full mb-2 '>
                      <div onClick={() => removeFields(index)} className='cursor-pointer px-2 pt-10 flex justify-center items-center '><span className='text-red-500 px-6 py-2 flex items-center justify-center'><RiDeleteBin6Fill size={26} /></span></div>
                    </div>
                }
                </div>
              </div>
            )
          }
          )
        }
        <div className='py-2 px-2 col-span-4'>
          <div className='flex justify-center'>
            <button onClick={handleMore} className='px-5 py-2 ml-1 flex items-center bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><span className='px-1'><AiFillPlusCircle /></span>Add Education</button>
          </div>
        </div>
        <div className='col-span-4 px-2 py-2  flex justify-end'>
          <button onClick={(e) => checkPrevious(e)} className='px-5 py-2 mr-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineLeft /></button>
          <button type='submit' className='px-5 py-2 mr-1 bg-teal-700 text-neutral-50 hover:bg-teal-800 delay-75 duration-75'>Save</button>
          <button onClick={(e) => checkNext(e)} className='px-5 py-2 ml-1 bg-neutral-700 text-neutral-50 hover:bg-neutral-800 delay-75 duration-75'><AiOutlineRight /></button>
        </div>
      </form>
    </div>
  )
}

export default Education